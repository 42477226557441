// variety filter module (support multi-select)
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'Aglianico', label: 'Aglianico' },
      { value: 'Aglianico Blend', label: 'Aglianico Blend' },
      { value: 'Albarino', label: 'Albarino' },
      { value: 'Alicante Bouschet', label: 'Alicante Bouschet' },
      { value: 'Alicante Bouschet Blend', label: 'Alicante Bouschet Blend' },
      { value: 'Aligoté', label: 'Aligoté' },
      { value: 'Alvarinho', label: 'Alvarinho' },
      { value: 'Aranel', label: 'Aranel' },
      { value: 'Arinto', label: 'Arinto' },
      { value: 'Arneis', label: 'Arneis' },
      { value: 'Arneis Blend', label: 'Arneis Blend' },
      { value: 'Assyrtiko', label: 'Assyrtiko' },
      { value: 'Barbera', label: 'Barbera' },
      { value: 'Barbera Blend', label: 'Barbera Blend' },
      { value: 'Bastardo', label: 'Bastardo' },
      { value: 'Bianco d\'Alessano', label: 'Bianco d\'Alessano' },
      { value: 'Bianco d\'Alessano Blend', label: 'Bianco d\'Alessano Blend' },
      { value: 'Black Frontignac', label: 'Black Frontignac' },
      { value: 'Black Muscat', label: 'Black Muscat' },
      { value: 'Blaufraenkisch', label: 'Blaufraenkisch' },
      { value: 'Blaufrankisch', label: 'Blaufrankisch' },
      { value: 'Blue Imperial', label: 'Blue Imperial' },
      { value: 'Bonvedro', label: 'Bonvedro' },
      { value: 'Brachetto', label: 'Brachetto' },
      { value: 'Brown Frontignac', label: 'Brown Frontignac' },
      { value: 'Brown Muscat', label: 'Brown Muscat' },
      { value: 'Brown Muscat Blend', label: 'Brown Muscat Blend' },
      { value: 'Cabernet', label: 'Cabernet' },
      { value: 'Cabernet Blend', label: 'Cabernet Blend' },
      { value: 'Cabernet Franc', label: 'Cabernet Franc' },
      { value: 'Cabernet Franc Blend', label: 'Cabernet Franc Blend' },
      { value: 'Cabernet Sauvignon', label: 'Cabernet Sauvignon' },
      { value: 'Cabernet Sauvignon Blend', label: 'Cabernet Sauvignon Blend' },
      { value: 'Cabernets', label: 'Cabernets' },
      { value: 'Campbell Blend', label: 'Campbell Blend' },
      { value: 'Canada Muscat', label: 'Canada Muscat' },
      { value: 'Canada Muscat Blend', label: 'Canada Muscat Blend' },
      { value: 'Carignan', label: 'Carignan' },
      { value: 'Carignan Blend', label: 'Carignan Blend' },
      { value: 'Carmenère', label: 'Carmenère' },
      { value: 'Carnelian', label: 'Carnelian' },
      { value: 'Carnelian Blend', label: 'Carnelian Blend' },
      { value: 'Chambourcin', label: 'Chambourcin' },
      { value: 'Chambourcin Blend', label: 'Chambourcin Blend' },
      { value: 'Chardonnay', label: 'Chardonnay' },
      { value: 'Chardonnay Blend', label: 'Chardonnay Blend' },
      { value: 'Chardonnay Musque', label: 'Chardonnay Musque' },
      { value: 'Chenin Blanc', label: 'Chenin Blanc' },
      { value: 'Chenin Blanc Blend', label: 'Chenin Blanc Blend' },
      { value: 'Cienna', label: 'Cienna' },
      { value: 'Cienna Blend', label: 'Cienna Blend' },
      { value: 'Cinsaut', label: 'Cinsaut' },
      { value: 'Cinsaut Blend', label: 'Cinsaut Blend' },
      { value: 'Clairette', label: 'Clairette' },
      { value: 'Colombard', label: 'Colombard' },
      { value: 'Colombard Blend', label: 'Colombard Blend' },
      { value: 'Colorino', label: 'Colorino' },
      { value: 'Cortese', label: 'Cortese' },
      { value: 'Crouchen', label: 'Crouchen' },
      { value: 'Crouchen Blend', label: 'Crouchen Blend' },
      { value: 'Cygne Blanc', label: 'Cygne Blanc' },
      { value: 'Dolcetto', label: 'Dolcetto' },
      { value: 'Dolcetto Blend', label: 'Dolcetto Blend' },
      { value: 'Doradillo', label: 'Doradillo' },
      { value: 'Durif', label: 'Durif' },
      { value: 'Durif Blend', label: 'Durif Blend' },
      { value: 'Fiano', label: 'Fiano' },
      { value: 'Fiano Blend', label: 'Fiano Blend' },
      { value: 'Frontignac', label: 'Frontignac' },
      { value: 'Frontignac Blend', label: 'Frontignac Blend' },
      { value: 'Fumé Blanc', label: 'Fumé Blanc' },
      { value: 'Gamay', label: 'Gamay' },
      { value: 'Gamay Blend', label: 'Gamay Blend' },
      { value: 'Garganega', label: 'Garganega' },
      { value: 'Garganega Blend', label: 'Garganega Blend' },
      { value: 'Garnacha', label: 'Garnacha' },
      { value: 'Garnacha Blend', label: 'Garnacha Blend' },
      { value: 'Gewurztraminer', label: 'Gewurztraminer' },
      { value: 'Gewürztraminer', label: 'Gewurztraminer' },
      { value: 'Gewurztraminer Blend', label: 'Gewurztraminer Blend' },
      { value: 'Gewürztraminer Blend', label: 'Gewurztraminer Blend' },
      { value: 'Glera', label: 'Glera' },
      { value: 'Glera Blend', label: 'Glera Blend' },
      { value: 'Gordo', label: 'Gordo' },
      { value: 'Gordo Blend', label: 'Gordo Blend' },
      { value: 'Graciano', label: 'Graciano' },
      { value: 'Graciano Blend', label: 'Graciano Blend' },
      { value: 'Greco', label: 'Greco' },
      { value: 'Grenache', label: 'Grenache' },
      { value: 'Grenache Blanc', label: 'Grenache Blanc' },
      { value: 'Grenache Blanc Blend', label: 'Grenache Blanc Blend' },
      { value: 'Grenache Blend', label: 'Grenache Blend' },
      { value: 'Grillo', label: 'Grillo' },
      { value: 'Gros Cabernet Blend', label: 'Gros Cabernet Blend' },
      { value: 'Gruner Veltliner', label: 'Gruner Veltliner' },
      { value: 'Grüner Veltliner', label: 'Gruner Veltliner' },
      { value: 'Gruner Veltliner Blend', label: 'Gruner Veltliner Blend' },
      { value: 'Isabella', label: 'Isabella' },
      { value: 'Kerner', label: 'Kerner' },
      { value: 'Kyoho', label: 'Kyoho' },
      { value: 'Lagrein', label: 'Lagrein' },
      { value: 'Lagrein Blend', label: 'Lagrein Blend' },
      { value: 'Lambrusco', label: 'Lambrusco' },
      { value: 'Lambrusco Maestri', label: 'Lambrusco Maestri' },
      { value: 'Malbec', label: 'Malbec' },
      { value: 'Malbec Blend', label: 'Malbec Blend' },
      { value: 'Malian Blend', label: 'Malian Blend' },
      { value: 'Malvasia Bianca', label: 'Malvasia Bianca' },
      { value: 'Mammolo', label: 'Mammolo' },
      { value: 'Manseng Petit Blanc', label: 'Manseng Petit Blanc' },
      { value: 'Marsanne', label: 'Marsanne' },
      { value: 'Marsanne Blend', label: 'Marsanne Blend' },
      { value: 'Marzemino', label: 'Marzemino' },
      { value: 'Mataro', label: 'Mataro' },
      { value: 'Mataro Blend', label: 'Mataro Blend' },
      { value: 'Melon', label: 'Melon' },
      { value: 'Mencia', label: 'Mencia' },
      { value: 'Merlot', label: 'Merlot' },
      { value: 'Merlot Blend', label: 'Merlot Blend' },
      { value: 'Meslier', label: 'Meslier' },
      { value: 'Meunier', label: 'Meunier' },
      { value: 'Monastrell', label: 'Monastrell' },
      { value: 'Monastrell Blend', label: 'Monastrell Blend' },
      { value: 'Mondeuse', label: 'Mondeuse' },
      { value: 'Montepulciano', label: 'Montepulciano' },
      { value: 'Montepulciano Blend', label: 'Montepulciano Blend' },
      { value: 'Moscato', label: 'Moscato' },
      { value: 'Moscato Bianco', label: 'Moscato Bianco' },
      { value: 'Moscato Blend', label: 'Moscato Blend' },
      { value: 'Moscato Giallo', label: 'Moscato Giallo' },
      { value: 'Moscato Giallo Blend', label: 'Moscato Giallo Blend' },
      { value: 'Mourvèdre', label: 'Mourvèdre' },
      { value: 'Mourvèdre Blend', label: 'Mourvèdre Blend' },
      { value: 'Mueller‐Thurgau Blend', label: 'Mueller‐Thurgau Blend' },
      { value: 'Muscadelle', label: 'Muscadelle' },
      { value: 'Muscadelle Blend', label: 'Muscadelle Blend' },
      { value: 'Muscadet', label: 'Muscadet' },
      { value: 'Muscat à petits grains blancs', label: 'Muscat à petits grains blancs' },
      { value: 'Muscat à petits grains blancs Blend', label: 'Muscat à petits grains blancs Blend' },
      { value: 'Muscat à petits grains noirs Blend', label: 'Muscat à petits grains noirs Blend' },
      { value: 'Muscat a petits grains roses', label: 'Muscat a petits grains roses' },
      { value: 'Muscat a petits grains roses Blend', label: 'Muscat a petits grains roses Blend' },
      { value: 'Muscat à petits grains rouges', label: 'Muscat à petits grains rouges' },
      { value: 'Muscat à petits grains rouges Blend', label: 'Muscat à petits grains rouges Blend' },
      { value: 'Muscat Blanc', label: 'Muscat Blanc' },
      { value: 'Muscat Blanc Blend', label: 'Muscat Blanc Blend' },
      { value: 'Muscat Flame', label: 'Muscat Flame' },
      { value: 'Muscat Fleur d\'Oranger', label: 'Muscat Fleur d\'Oranger' },
      { value: 'Muscat Gordo Blanco', label: 'Muscat Gordo Blanco' },
      { value: 'Muscat Gordo Blanco Blend', label: 'Muscat Gordo Blanco Blend' },
      { value: 'Muscat Hamburg', label: 'Muscat Hamburg' },
      { value: 'Muscat Museum', label: 'Muscat Museum' },
      { value: 'Muscat Museum Blend', label: 'Muscat Museum Blend' },
      { value: 'Muscat of Alexandria', label: 'Muscat of Alexandria' },
      { value: 'Muscat of Alexandria Blend', label: 'Muscat of Alexandria Blend' },
      { value: 'Nebbiolo', label: 'Nebbiolo' },
      { value: 'Nebbiolo Blend', label: 'Nebbiolo Blend' },
      { value: 'Negro Amaro', label: 'Negro Amaro' },
      { value: 'Negro Amaro Blend', label: 'Negro Amaro Blend' },
      { value: 'Nero d\'Avola', label: 'Nero d\'Avola' },
      { value: 'Nero d\'Avola Blend', label: 'Nero d\'Avola Blend' },
      { value: 'No Variety Label Claim', label: 'No Variety Label Claim' },
      { value: 'Nosiola', label: 'Nosiola' },
      { value: 'Ondenc', label: 'Ondenc' },
      { value: 'Orange Muscat', label: 'Orange Muscat' },
      { value: 'Orange Muscat Blend', label: 'Orange Muscat Blend' },
      { value: 'Palomino', label: 'Palomino' },
      { value: 'Palomino Blend', label: 'Palomino Blend' },
      { value: 'Pedro Ximenez', label: 'Pedro Ximenez' },
      { value: 'Pedro Ximenez Blend', label: 'Pedro Ximenez Blend' },
      { value: 'Pedro Ximenes', label: 'Pedro Ximenes' },
      { value: 'Pedro Ximenes Blend', label: 'Pedro Ximenes Blend' },
      { value: 'Petit Manseng', label: 'Petit Manseng' },
      { value: 'Petit Meslier', label: 'Petit Meslier' },
      { value: 'Petit Meslier Blend', label: 'Petit Meslier Blend' },
      { value: 'Petit Verdot', label: 'Petit Verdot' },
      { value: 'Petit Verdot Blend', label: 'Petit Verdot Blend' },
      { value: 'Petite Sirah', label: 'Petite Sirah' },
      { value: 'Petite Sirah Blend', label: 'Petite Sirah Blend' },
      { value: 'Picolit', label: 'Picolit' },
      { value: 'Picpoul de Pinet', label: 'Picpoul de Pinet' },
      { value: 'Pinot Blanc', label: 'Pinot Blanc' },
      { value: 'Pinot Blanc Blend', label: 'Pinot Blanc Blend' },
      { value: 'Pinot Noir & Chardonnay', label: 'Pinot Chardonnay' },
      { value: 'Pinot Grigio', label: 'Pinot Grigio' },
      { value: 'Pinot Grigio Blend', label: 'Pinot Grigio Blend' },
      { value: 'Pinot Gris', label: 'Pinot Gris' },
      { value: 'Pinot Gris Blend', label: 'Pinot Gris Blend' },
      { value: 'Pinot Meunier', label: 'Pinot Meunier' },
      { value: 'Pinot Meunier Blend', label: 'Pinot Meunier Blend' },
      { value: 'Pinot Nero', label: 'Pinot Nero' },
      { value: 'Pinot Noir', label: 'Pinot Noir' },
      { value: 'Pinot Noir Blend', label: 'Pinot Noir Blend' },
      { value: 'Pinotage', label: 'Pinotage' },
      { value: 'Pinotage Blend', label: 'Pinotage Blend' },
      { value: 'Primitivo', label: 'Primitivo' },
      { value: 'Primitivo Blend', label: 'Primitivo Blend' },
      { value: 'Prosecco', label: 'Prosecco' },
      { value: 'Prosecco Blend', label: 'Prosecco Blend' },
      { value: 'Red Frontignac', label: 'Red Frontignac' },
      { value: 'Refosco dal Peduncolo Rosso', label: 'Refosco dal Peduncolo Rosso' },
      { value: 'Riesling', label: 'Riesling' },
      { value: 'Riesling Blend', label: 'Riesling Blend' },
      { value: 'Rondinella Blend', label: 'Rondinella Blend' },
      { value: 'Roussanne', label: 'Roussanne' },
      { value: 'Roussanne Blend', label: 'Roussanne Blend' },
      { value: 'Ruby Cabernet', label: 'Ruby Cabernet' },
      { value: 'Ruby Cabernet Blend', label: 'Ruby Cabernet Blend' },
      { value: 'Sagrantino', label: 'Sagrantino' },
      { value: 'Sagrantino Blend', label: 'Sagrantino Blend' },
      { value: 'Saint‐Macaire', label: 'Saint‐Macaire' },
      { value: 'Sangiovese', label: 'Sangiovese' },
      { value: 'Sangiovese Blend', label: 'Sangiovese Blend' },
      { value: 'Saperavi', label: 'Saperavi' },
      { value: 'Saperavi Blend', label: 'Saperavi Blend' },
      { value: 'Sauvignon', label: 'Sauvignon' },
      { value: 'Sauvignon Blanc', label: 'Sauvignon Blanc' },
      { value: 'Sauvignon Blanc Blend', label: 'Sauvignon Blanc Blend' },
      { value: 'Sauvignon Rose', label: 'Sauvignon Rose' },
      { value: 'Sauvignonasse', label: 'Sauvignonasse' },
      { value: 'Sauvignonasse Blend', label: 'Sauvignonasse Blend' },
      { value: 'Savagnin', label: 'Savagnin' },
      { value: 'Savagnin Blanc', label: 'Savagnin Blanc' },
      { value: 'Savagnin Blanc Blend', label: 'Savagnin Blanc Blend' },
      { value: 'Savagnin Blend', label: 'Savagnin Blend' },
      { value: 'Schonburger', label: 'Schonburger' },
      { value: 'Semillon', label: 'Semillon' },
      { value: 'Semillon Blend', label: 'Semillon Blend' },
      { value: 'Sercial', label: 'Sercial' },
      { value: 'Shalistin', label: 'Shalistin' },
      { value: 'Shiraz', label: 'Shiraz' },
      { value: 'Shiraz Blend', label: 'Shiraz Blend' },
      { value: 'Sultana Blend', label: 'Sultana Blend' },
      { value: 'Sylvaner', label: 'Sylvaner' },
      { value: 'Sylvaner Blend', label: 'Sylvaner Blend' },
      { value: 'Syrah', label: 'Syrah' },
      { value: 'Syrah Blend', label: 'Syrah Blend' },
      { value: 'Taminga', label: 'Taminga' },
      { value: 'Taminga Blend', label: 'Taminga Blend' },
      { value: 'Tannat', label: 'Tannat' },
      { value: 'Tannat Blend', label: 'Tannat Blend' },
      { value: 'Tarrango', label: 'Tarrango' },
      { value: 'Tarrango Blend', label: 'Tarrango Blend' },
      { value: 'Tempranillo', label: 'Tempranillo' },
      { value: 'Tempranillo Blend', label: 'Tempranillo Blend' },
      { value: 'Teroldego', label: 'Teroldego' },
      { value: 'Teroldego Blend', label: 'Teroldego Blend' },
      { value: 'Tinta Amarella', label: 'Tinta Amarella' },
      { value: 'Tinta Câo Blend', label: 'Tinta Câo Blend' },
      { value: 'Tinta Negra Mole', label: 'Tinta Negra Mole' },
      { value: 'Tinta Roriz', label: 'Tinta Roriz' },
      { value: 'Tinta Roriz Blend', label: 'Tinta Roriz Blend' },
      { value: 'Tokay', label: 'Tokay' },
      { value: 'Topaque', label: 'Topaque' },
      { value: 'Touriga', label: 'Touriga' },
      { value: 'Touriga Blend', label: 'Touriga Blend' },
      { value: 'Touriga Nacional', label: 'Touriga Nacional' },
      { value: 'Touriga Nacional Blend', label: 'Touriga Nacional Blend' },
      { value: 'Traminer', label: 'Traminer' },
      { value: 'Traminer Blend', label: 'Traminer Blend' },
      { value: 'Trebbiano', label: 'Trebbiano' },
      { value: 'Trebbiano Blend', label: 'Trebbiano Blend' },
      { value: 'Trincadeira Preta', label: 'Trincadeira Preta' },
      { value: 'Trollinger Blend', label: 'Trollinger Blend' },
      { value: 'Trousseau', label: 'Trousseau' },
      { value: 'Tyrian', label: 'Tyrian' },
      { value: 'Ugni Blanc Blend', label: 'Ugni Blanc Blend' },
      { value: 'Verdejo', label: 'Verdejo' },
      { value: 'Verdelho', label: 'Verdelho' },
      { value: 'Verdelho Blend', label: 'Verdelho Blend' },
      { value: 'Verdicchio Bianco', label: 'Verdicchio Bianco' },
      { value: 'Verduzzo', label: 'Verduzzo' },
      { value: 'Vermentino', label: 'Vermentino' },
      { value: 'Vermentino Blend', label: 'Vermentino Blend' },
      { value: 'Viognier', label: 'Viognier' },
      { value: 'Viognier Blend', label: 'Viognier Blend' },
      { value: 'Waltham Cross', label: 'Waltham Cross' },
      { value: 'White Frontignac', label: 'White Frontignac' },
      { value: 'White Frontignac Blend', label: 'White Frontignac Blend' },
      { value: 'Zibibbo', label: 'Zibibbo' },
      { value: 'Zinfandel', label: 'Zinfandel' },
      { value: 'Zinfandel Blend', label: 'Zinfandel Blend' },

    ],
    selections: [],

  },

  mutations: {
    updateSelections(state, items) {
      Vue.set(state, 'selections', [...items]);
      console.log(`Selected variety count: ${state.selections.length}`);
      for (let i = 0; i < state.selections.length; i += 1) {
        console.log(`Selected variety: ${state.selections[i].value}`);
      }
    },
  },

  getters: {
    selectedItems: state => state.selections,
    selectedValueArray: state => state.selections.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []),
  },

  actions: {

  },


};
