<template>
  <div id="map">
    <b-row>
      <b-col lg="3">
        <p class="heading">Market A</p>
        <v-select @input="setActiveComparisonACountry" :options="$store.state.country.options" :value="$store.getters['country/selectedComparisonAItem']" :searchable="true" placeholder=""></v-select>
      </b-col>
      <b-col lg="3">
        <p class="heading">Market B</p>
        <v-select @input="setActiveComparisonBCountry" :options="$store.state.country.options" :value="$store.getters['country/selectedComparisonBItem']" :searchable="true" placeholder=""></v-select>
      </b-col>
      <b-col lg="3">
        <p class="heading">Market C</p>
        <v-select @input="setActiveComparisonCCountry" :options="$store.state.country.options" :value="$store.getters['country/selectedComparisonCItem']" :searchable="true" placeholder=""></v-select>
      </b-col>
      <!-- <b-col lg="2">
        <p>&nbsp;</p>
      </b-col> -->
      <!-- <b-col lg="1">
        <p class="heading">&nbsp;</p>
        <p class="heading text-right d-none d-lg-block">
        <b-link class="heading" to="/print/MA11" target="_blank" style="color: #777" >
          <font-awesome-icon icon="print" />
        </b-link>
        </p>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col xl="12">
        &nbsp;<br />
        &nbsp;
      </b-col>
    </b-row>

    <b-row v-if="pageLoaded && !isMobile">
      <b-col lg="12" class="nopadding">
        <div>
          <MA11
            v-if="pageLoaded"
            @loaded= "reportLoaded()"
          />
        </div>
      </b-col>
    </b-row>


    <b-row v-if="pageLoaded && isMobile">
      <b-col cols="6" sm="6" md="6" lg="3" xl="3" class="nopadding">
        <div>
          <p class="heading">&nbsp;</p>
          <MA11Labels
            v-if="pageLoaded"
            countryIndex="A"
            @loaded= "reportLoaded()"
          />
        </div>
      </b-col>
      <b-col cols="6" sm="6" md="6" lg="3" xl="3" class="text-center nopadding">
        <div>
        <p class="heading" >{{ countryA }}</p>
          <MA11Country
            countryIndex="A"
            @loaded= "reportLoaded()"
          />
        </div>
      </b-col>
      <b-col class="d-block d-lg-none text-center nopadding" cols="6" sm="6" md="6" lg="3" xl="3" >
        <div>
          <p class="heading">&nbsp;</p>
          <MA11Labels
            v-if="pageLoaded && isMobile"
            countryIndex="B"
            @loaded= "reportLoaded()"
          />
        </div>
      </b-col>
      <b-col cols="6" sm="6" md="6" lg="3" xl="3" class="text-center nopadding">
        <div>
          <p class="heading" >{{ countryB }}</p>
          <MA11Country
            countryIndex="B"
            @loaded= "reportLoaded()"
          />
        </div>
      </b-col>
      <b-col class="d-block d-lg-none text-center nopadding" cols="6" sm="6" md="6" lg="3" xl="3">
        <div>
          <p class="heading">&nbsp;</p>
          <MA11Labels
            v-if="pageLoaded && isMobile"
            countryIndex="C"
            @loaded= "reportLoaded()"
          />
        </div>
      </b-col>
      <b-col cols="6" sm="6" md="6" lg="3" xl="3" class="text-center nopadding">
        <div>
          <p class="heading">{{ countryC }}</p>
          <MA11Country
            countryIndex="C"
            @loaded= "reportLoaded()"
          />
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import MA11 from '@/components/reports/MA11.vue';
import MA11Labels from '@/components/reports/MA11Labels.vue';
import MA11Country from '@/components/reports/MA11Country.vue';
import vSelect from '@/components/widgets/CustomSelect/components/CustomSelect.vue';

export default {
  name: 'MarketComparison',
  components: {
    MA11,
    MA11Labels,
    MA11Country,
    vSelect,
  },
  data() {
    return {
      pbiEmbedConfig: '',
      pageLoaded: false,
      isMobile: false,
    };
  },
  watch: {
  },

  methods: {
    printClicked() {
      console.log('print clicked');
      window.print();
    },

    setActiveComparisonACountry(val) {
      console.log(`set comparison A country: ${val.value}`);
      if (val != null) {
        this.$store.commit('country/updateSelectionComparisonA', val);
      }
    },

    setActiveComparisonBCountry(val) {
      console.log(`set comparison B country: ${val.value}`);
      if (val != null) {
        this.$store.commit('country/updateSelectionComparisonB', val);
      }
    },

    setActiveComparisonCCountry(val) {
      console.log(`set comparison C country: ${val.value}`);
      if (val != null) {
        this.$store.commit('country/updateSelectionComparisonC', val);
      }
    },

    reportLoaded() {
      console.log('MA11 reportLoaded()');
      this.processWindowResize();
    },

    processWindowResize() {
      if (this.resizeDebounceTimeout != null) {
        clearTimeout(this.resizeDebounceTimeout);
      }

      this.resizeDebounceTimeout = setTimeout(() => {
        const width = document.getElementById('containerDiv').offsetWidth;
        const height = document.getElementById('containerDiv').offsetHeight;

        console.log(`page resized! ${width} x ${height}`);
        console.log(`isMobile = : ${width < 992}`);

        this.isMobile = width < 992;

      // const newSettings = {
      //   // Settings accounting for custom layout with new size
      // };
      // visual.updateSettings(newSettings);
      }, 1000);
    },
  },

  mounted() {
    // add a resize event handler with debounce
    window.addEventListener('resize', this.processWindowResize);

    this.pageLoaded = true;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.processWindowResize);
  },


  computed: {
    environment() {
      return this.$store.state.global.environment;
    },

    countryA() {
      return this.$store.getters['country/selectedComparisonAItem'].label;
    },

    countryB() {
      return this.$store.getters['country/selectedComparisonBItem'].label;
    },

    countryC() {
      return this.$store.getters['country/selectedComparisonCItem'].label;
    },
  },
};

</script>


<style scoped>

p.heading {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.7rem;
    font-weight: 400;
}

.list-group-item {
  border: 0 none !important;
}

#map {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

/* .marked {
  min-height: 1000px;
  height: 1000px;
} */

</style>

<style>

</style>
