// region filter module (supports multi-select)
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'AFRICA', label: 'Africa' },
      { value: 'ASIA - OTHER', label: 'Asia - other' },
      { value: 'CENTRAL AMERICA', label: 'Central America' },
      { value: 'EUROPE - EU', label: 'Europe - EU' },
      { value: 'EUROPE - OTHER', label: 'Europe - Other' },
      { value: 'INDIAN OCEAN', label: 'Indian Ocean' },
      { value: 'MIDDLE EAST', label: 'Middle East' },
      { value: 'NORTH AMERICA', label: 'North America' },
      { value: 'NORTH EAST ASIA', label: 'North-East Asia' },
      { value: 'OCEANIA', label: 'Oceania' },
      { value: 'SOUTH AMERICA', label: 'South America' },
      { value: 'SOUTH EAST ASIA', label: 'South-East Asia' },
      { value: 'WEST INDIES/CARRIBEAN', label: 'West Indies / Carribean' },
    ],
    selections: [],

  },

  mutations: {
    updateSelections(state, regions) {
      Vue.set(state, 'selections', [...regions]);
      console.log(`Selected region count: ${state.selections.length}`);
      for (let i = 0; i < state.selections.length; i += 1) {
        console.log(`Selected region: ${state.selections[i].value}`);
      }
    },
  },

  getters: {
    selectedItems: state => state.selections,
    selectedValueArray: state => state.selections.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []),
  },

  actions: {

  },


};
