// wine style filter module (supports multiple selection)
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'Wine red', label: 'Wine red' },
      { value: 'Wine white', label: 'Wine white' },
      { value: 'Wine rosé', label: 'Wine rosé' },
      { value: 'Sparkling wine', label: 'Sparkling wine' },
      { value: 'Carbonated wine', label: 'Carbonated wine' },
      { value: 'Semi-carbonated wine', label: 'Semi-carbonated wine' },
      { value: 'Fortified wine', label: 'Fortified wine' },
      { value: 'Dessert wine', label: 'Dessert wine' },
      { value: 'Vermouth', label: 'Vermouth' },

    ],
    selections: [],

  },

  mutations: {
    updateSelections(state, items) {
      Vue.set(state, 'selections', [...items]);
      console.log(`Selected wine style count: ${state.selections.length}`);
      for (let i = 0; i < state.selections.length; i += 1) {
        console.log(`Selected wine style: ${state.selections[i].value}`);
      }
    },
  },

  getters: {
    selectedItems: state => state.selections,
    selectedValueArray: state => state.selections.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []),
  },

  actions: {

  },


};
