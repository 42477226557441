export default {
  items: [
    {
      name: 'Explore Markets',
      url: '/explore',
    },
    {
      name: 'Market Summary',
      url: '/market-summary',
    },
    {
      name: 'Market List',
      url: '/market-list',
    },
    {
      name: 'Market Comparison',
      url: '/market-comparison',
    },
    {
      name: 'New Products',
      url: '/new-products',
    },
    {
      name: 'New Markets',
      url: '/new-markets',
    },
  ],
};
