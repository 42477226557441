<template>
  <div id="map">
    <b-row>
      <b-col lg="5">
        <p class="heading">I'm interested in</p>
        <v-select @input="setActiveInterestArea" :options="isMobile ? $store.state.interestArea.optionsMobile : $store.state.interestArea.options" :value="$store.getters['interestArea/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <b-col lg="1">
        <p class="heading">Year</p>
        <v-select @input="setActiveYear" :options="yearOptions" :value="$store.getters['year/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <b-col v-if="valueTypeVisible" lg="2">
        <p class="heading">Measures</p>
        <v-select @input="setActiveValueType" :options="$store.state.valueType.options" :value="$store.getters['valueType/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <b-col v-else lg="2">
      </b-col>
      <b-col v-if="valueTypeVisible" lg="2">
        <p class="heading">Reporting period</p>
        <v-select @input="setActiveValueMeasure" :options="$store.state.valueMeasure.options" :value="$store.getters['valueMeasure/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <b-col v-else lg="2">
      </b-col>
      <!-- <b-col lg="3">
        <p class="heading">Market classification</p>
        <v-select @input="setActiveMarketClassifications" :options="$store.state.global.marketClassificationOptions" :value="marketClassifications" multiple placeholder="All classifications"></v-select>
      </b-col> -->
      <b-col lg="2">
        <p class="heading">Region</p>
        <v-select @input="setActiveRegions" :options="$store.state.region.options" :value="regions" multiple :searchable="false" placeholder="All regions"></v-select>
      </b-col>
      <!-- <b-col lg="1">
        <p class="heading">&nbsp;</p>
        <p class="heading text-right d-none d-lg-block">
        <b-link class="heading" to="/print/MA33" target="_blank" style="color: #777" >
          <font-awesome-icon icon="print" />
        </b-link>
        </p>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col lg="12">
        &nbsp;
      </b-col>
    </b-row>
    <b-row >
      <b-col lg="12" >
        <div>

        <MA331 v-if="pageLoaded && $store.getters['interestArea/selectedItem'].value==='MA-33-1' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA331Mobile v-if="pageLoaded && $store.getters['interestArea/selectedItem'].value==='MA-33-1' && isMobile"
          @loaded= "reportLoaded()"
        />

        <MA332 v-if="pageLoaded && $store.getters['interestArea/selectedItem'].value==='MA-33-2' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA332Mobile v-if="pageLoaded && $store.getters['interestArea/selectedItem'].value==='MA-33-2' && isMobile"
          @loaded= "reportLoaded()"
        />

        <MA333 v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-3' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA333Mobile v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-3' && isMobile"
          @loaded= "reportLoaded()"
        />

        <MA334 v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-4' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA334Mobile v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-4' && isMobile"
          @loaded= "reportLoaded()"
        />

        <MA335 v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-5' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA335Mobile v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-5' && isMobile"
          @loaded= "reportLoaded()"
        />

        <MA336 v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-6' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA336Mobile v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-6' && isMobile"
          @loaded= "reportLoaded()"
        />

        <MA337 v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-7' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA337Mobile v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-7' && isMobile"
          @loaded= "reportLoaded()"
        />

        <MA338 v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-8' && !isMobile"
          @loaded= "reportLoaded()"
        />
        <MA338Mobile v-if="$store.getters['interestArea/selectedItem'].value==='MA-33-8' && isMobile"
          @loaded= "reportLoaded()"
        />

        </div>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-link @click="poo">Poo</b-link>
    </b-row> -->
    <b-row>
      <b-col class="d-none d-lg-block" lg="7">
        <b-img src="/img/MapLegend.jpg" fluid alt="Map Legend" />
      </b-col>
      <b-col class="d-block d-lg-none" lg="7">
        &nbsp;
      </b-col>
      <b-col lg="5">
        &nbsp;
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MA331 from '@/components/reports/MA331.vue';
import MA331Mobile from '@/components/reports/MA331Mobile.vue';
import MA332 from '@/components/reports/MA332.vue';
import MA332Mobile from '@/components/reports/MA332Mobile.vue';
import MA333 from '@/components/reports/MA333.vue';
import MA333Mobile from '@/components/reports/MA333Mobile.vue';
import MA334 from '@/components/reports/MA334.vue';
import MA334Mobile from '@/components/reports/MA334Mobile.vue';
import MA335 from '@/components/reports/MA335.vue';
import MA335Mobile from '@/components/reports/MA335Mobile.vue';
import MA336 from '@/components/reports/MA336.vue';
import MA336Mobile from '@/components/reports/MA336Mobile.vue';
import MA337 from '@/components/reports/MA337.vue';
import MA337Mobile from '@/components/reports/MA337Mobile.vue';
import MA338 from '@/components/reports/MA338.vue';
import MA338Mobile from '@/components/reports/MA338Mobile.vue';
import vSelect from '@/components/widgets/CustomSelect/components/CustomSelect.vue';


export default {
  name: 'Explore',
  components: {
    MA331,
    MA331Mobile,
    MA332,
    MA332Mobile,
    MA333,
    MA333Mobile,
    MA334,
    MA334Mobile,
    MA335,
    MA335Mobile,
    MA336,
    MA336Mobile,
    MA337,
    MA337Mobile,
    MA338,
    MA338Mobile,
    vSelect,
  },
  data() {
    return {
      pbiEmbedConfig: '',
      // marketClassifications: [],
      regions: [],
      pageLoaded: false,
      isMobile: false,
    };
  },
  watch: {
    // selectedMarketClassifications(val) {
    //   // const filters = [];
    //   // for (let i = 0; i < val.length; i += 1) {
    //   //   console.log(val[i].value);
    //   //   filters.push(val[i].value);
    //   // }
    //   // this.filter.values = filters;
    // },

  },

  methods: {
    resetToDefaultFilterValues() {
      switch (this.$store.getters['interestArea/selectedItem'].value) {
        default:
          this.setActiveYear({ value: 2022, label: '2022' });
          break;
      }
      this.setActiveValueType({ value: 'Retail Value', label: 'Value' });
      this.setActiveValueMeasure({ value: 'annual', label: 'Full year' });
      this.setActiveRegions([]);
    },

    setActiveInterestArea(val) {
      console.log(`set interest area: ${val.index} - ${val.value}`);

      if (val != null) {
        this.setActiveInterestAreaByIndex(val.index);
      }
    },

    setActiveInterestAreaByIndex(idx) {
      console.log(`set interest area by index: ${idx}`);

      if (idx != null) {
        this.$store.commit(
          this.isMobile ? 'interestArea/updateSelectedIndexMobile' : 'interestArea/updateSelectedIndex',
          idx,
        );
        let question = '';

        switch (idx) {
          case 0:
            question = 'which-markets-drink-the-most-wine';
            break;
          case 1:
            question = 'how-does-the-average-wine-price-per-bottle-compare-across-markets';
            break;
          case 2:
            question = 'which-markets-consume-the-most-imported-wine';
            break;
          case 3:
            question = 'what-is-the-share-of-on-versus-off-premise-in-each-market';
            break;
          case 4:
            question = 'how-much-wine-consumed-in-each-market-is-premium';
            break;
          case 5:
            question = 'what-is-wine-share-of-the-total-alcohol-market';
            break;
          case 6:
            question = 'where-is-australian-wine-exported-to';
            break;
          case 7:
            question = 'where-are-australian-wines-exported-to-by-price-point';
            break;

          default:
        }


        this.$router.push(`/explore/${question}`);
      }
    },

    setActiveYear(val) {
      console.log(`set year: ${val.value}`);
      if (val != null) {
        this.$store.commit('year/updateSelection', val);
      }
    },

    setActiveValueType(val) {
      console.log(`set value type: ${val.value}`);
      if (val != null) {
        this.$store.commit('valueType/updateSelection', val);
      }
    },

    setActiveValueMeasure(val) {
      console.log(`set value measure: ${val.value}`);
      if (val != null) {
        this.$store.commit('valueMeasure/updateSelection', val);
      }
    },

    setActiveRegions(val) {
      console.log(`set regions: ${val.length}`);
      this.$store.commit('region/updateSelections', val);
    },

    reportLoaded() {
      console.log('report loaded called');
      if (this.$store.getters['interestArea/selectedItem'].value === 'MA-33-4') {
        if (this.$store.getters['year/selectedItem'].value < 2016) {
          // year is below minimum... let's select the minimum instead
          this.setActiveYear({ value: 2016, label: '2016' });
        }
      }
      if (this.$store.getters['interestArea/selectedItem'].value === 'MA-33-6') {
        if (this.$store.getters['year/selectedItem'].value < 2014) {
          // year is below minimum... let's select the minimum instead
          this.setActiveYear({ value: 2014, label: '2014' });
        }
        // if (this.$store.getters['year/selectedItem'].value > 2016) {
        //   // year is below minimum... let's select the minimum instead
        //   this.setActiveYear({ value: 2016, label: '2016' });
        // }
      }

      if (this.$store.getters['year/selectedItem'].value === 2019) {
        this.setActiveYear({ value: 2019, label: '2019' });
      }

      this.processWindowResize();
    },

    processWindowResize() {
      if (this.resizeDebounceTimeout != null) {
        clearTimeout(this.resizeDebounceTimeout);
      }

      this.resizeDebounceTimeout = setTimeout(() => {
        const width = document.getElementById('containerDiv').offsetWidth;
        const height = document.getElementById('containerDiv').offsetHeight;

        console.log(`page resized! ${width} x ${height}`);
        console.log(`isMobile = : ${width < 992}`);

        this.isMobile = width < 992;

      // const newSettings = {
      //   // Settings accounting for custom layout with new size
      // };
      // visual.updateSettings(newSettings);
      }, 1000);
    },

  },

  mounted() {
    switch (this.$route.params.question) {
      case 'which-markets-drink-the-most-wine':
        this.setActiveInterestAreaByIndex(0);
        break;
      case 'how-does-the-average-wine-price-per-bottle-compare-across-markets':
        this.setActiveInterestAreaByIndex(1);
        break;
      case 'which-markets-consume-the-most-imported-wine':
        this.setActiveInterestAreaByIndex(2);
        break;
      case 'what-is-the-share-of-on-versus-off-premise-in-each-market':
        this.setActiveInterestAreaByIndex(3);
        break;
      case 'how-much-wine-consumed-in-each-market-is-premium':
        this.setActiveInterestAreaByIndex(4);
        break;
      case 'what-is-wine-share-of-the-total-alcohol-market':
        this.setActiveInterestAreaByIndex(5);
        break;
      case 'where-is-australian-wine-exported-to':
        this.setActiveInterestAreaByIndex(6);
        break;
      case 'where-are-australian-wines-exported-to-by-price-point':
        this.setActiveInterestAreaByIndex(7);
        break;

      default:
    }

    const width = document.getElementById('containerDiv').offsetWidth;
    const height = document.getElementById('containerDiv').offsetHeight;

    console.log(`page resized! ${width} x ${height}`);
    console.log(`isMobile = : ${width < 992}`);

    this.isMobile = width < 992;


    // add a resize event handler with debounce
    window.addEventListener('resize', this.processWindowResize);


    // reset filters to default for page
    this.resetToDefaultFilterValues();

    this.regions = [...this.$store.getters['region/selectedItems']];

    this.pageLoaded = true;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.processWindowResize);
  },


  computed: {

    yearOptions() {
      switch (this.$store.getters['interestArea/selectedItem'].value) {
        case 'MA-33-4':
          return this.$store.state.year.options2016;
        case 'MA-33-6':
          return this.$store.state.year.options2017;
        default:
          return this.$store.state.year.options;
      }
    },

    valueTypeVisible() {
      switch (this.$store.getters['interestArea/selectedItem'].value) {
        case 'MA-33-2':
        case 'MA-33-4':
        case 'MA-33-6':
          return false;
        default:
          return true;
      }
    },

    environment() {
      return this.$store.state.global.environment;
    },

  },
};

</script>


<style scoped>

p.heading {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.7rem;
    font-weight: 400;
}

.list-group-item {
  border: 0 none !important;
}

#map {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

/* .marked {
  min-height: 1000px;
  height: 1000px;
} */


</style>

<style>

.v-select .dropdown-toggle .clear {
  visibility: hidden;
}

.v-select .selected-tag {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #888 !important;
}

.v-select ::-webkit-input-placeholder {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media (max-width: 992) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media (max-width: 992) {
  ul.dropdown-menu {
    font-size: 16px !important;
  }

}
</style>
