import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import global from './modules/global';

// Filters
import interestArea from './modules/filters/interestArea';
import year from './modules/filters/year';
import stickyYear from './modules/filters/stickyYear';
import yearEnding from './modules/filters/yearEnding';
import country from './modules/filters/country';
import region from './modules/filters/region';
import valueType from './modules/filters/valueType';
import valueMeasure from './modules/filters/valueMeasure';
import variety from './modules/filters/variety';
import giRegion from './modules/filters/giRegion';
import fobPricePerLitre from './modules/filters/fobPricePerLitre';
import wineStyle from './modules/filters/wineStyle';
import container from './modules/filters/container';
import market from './modules/filters/market';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // Modules
    global,

    // filters
    interestArea,
    year,
    stickyYear,
    yearEnding,
    country,
    region,
    valueType,
    valueMeasure,
    variety,
    giRegion,
    fobPricePerLitre,
    wineStyle,
    container,
    market,


  },

  strict: process.env.NODE_ENV !== 'production',
});
