<template>
  <div class="desktop-view" align="center" >
    <div :id="containerId" class="embedContainer" />
  </div>
</template>

<script>
import axios from 'axios';
import * as pbi from 'powerbi-client';

export default {
  name: 'PbiReport_MA_33_3_Revised',
  components: {
  },

  props: [
    'width',
    'height',
  ],

  data() {
    return {
      pbiEmbedConfig: '',
      report: null,
      resizeDebounceTimeout: null,
    };
  },

  methods: {
    applyFilters() {
      console.log('set report filter MA333');

      if (this.report != null) {
        if (this.filters.length > 0) {
          this.report.setFilters(this.filters)
            .catch((errors) => {
              console.log(errors);
            // Handle error
            });
        } else {
          console.log('clearing filter');
          this.report.removeFilters();
        }


        // const displayRetailValueRanking = this.filters[1].values[0] === 'Volume';

        const { models } = pbi;

        const newSettings = {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          background: models.BackgroundType.Transparent,
          layoutType: models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToPage,
            pagesLayout: {

              // Value
              ReportSection76fdb174b8215a2566c4: {
                defaultLayout: {
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Visible,
                  },
                },
                visualsLayout: {
                  '62b603620bca8057907e': { // "Market Share by Value" pie chart
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Visible,
                    },
                  },
                  ae424840009400731045: { // "Import Value by Country of Origin" Bar chart
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Visible,
                    },
                  },
                  // These visuals are HIDDEN by default
                   '8870cf62079079a85ea7': { // Help tip text box (hidden)
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                   '136b6f9cbd328c2c5e8d': { // Help tip text box close action button
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Hidden,
                    },
                  },                 

                  // Annual
                  b1ad79be9733e03e5950: { // "Imported Retail Value by Country" map
                    displayState: {
                      mode: this.vm[0] === 'annual' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                  '72c410b529d6668a2145': { // "Rank by Total Import Value" table
                    displayState: {
                      mode: this.vm[0] === 'annual' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },

                  // Growth-YoY
                  '7b88d0be535d76180a6d': { // "Imported Retail Value (Year on Year) by Country" map
                    displayState: {
                      mode: this.vm[0] === 'growth-yoy' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                  '9914dd6a57912cd2a820': { // "Rank by Total Import Value (Year on Year)" table
                    displayState: {
                      mode: this.vm[0] === 'growth-yoy' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },

                  // Growth-5Yr
                  b00bb400585303eb1415: { // "Imported Retail Value (5 Year CAGR) by Country" map
                    displayState: {
                      mode: this.vm[0] === 'growth-5yr' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                  '53ea2bed73d193177cdc': { // "Rank by Total Import Value (5 Year CAGR)" table
                    displayState: {
                      mode: this.vm[0] === 'growth-5yr' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },


                },
              },

              // Volume
              ReportSection65002c80008ee0027449: {
                defaultLayout: {
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Visible,
                  },
                },
                visualsLayout: {
                  '62b603620bca8057907e': { // "Market Share by Value" pie chart
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Visible,
                    },
                  },
                  ae424840009400731045: { // "Import Value by Country of Origin" Bar chart
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Visible,
                    },
                  },
                  // These visuals are HIDDEN by default
                  e51c1c82b14383cd1a15: { // Help tip text box (hidden)
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                   dfff9041aabe58b062b7: { // Help tip text box close action button
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Hidden,
                    },
                  }, 

                  // Annual
                  '7b88d0be535d76180a6d': { // "Import Volume by Country" map
                    displayState: {
                      mode: this.vm[0] === 'annual' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                  '9914dd6a57912cd2a820': { // "Rank by Total Import Volume" table
                    displayState: {
                      mode: this.vm[0] === 'annual' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },

                  // Growth-YoY
                  '41c1c8e1a70033920ba6': { // Import Volume by Country (Year on Year) map
                    displayState: {
                      mode: this.vm[0] === 'growth-yoy' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                  '7c216086b083e693d0cd': { // "Rank by Total Import Volume (Year on Year)" table
                    displayState: {
                      mode: this.vm[0] === 'growth-yoy' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },

                  // Growth-5Yr
                  d5cd71fc55e9d9122ca4: { // "Import Volume by Country (5 Year CAGR) map
                    displayState: {
                      mode: this.vm[0] === 'growth-5yr' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                  '500161a17c0839cc7a0a': { // "Rank by Total Import Volume (5 Year CAGR)" table
                    displayState: {
                      mode: this.vm[0] === 'growth-5yr' ? models.VisualContainerDisplayMode.Visible : models.VisualContainerDisplayMode.Hidden,
                    },
                  },
                },
              },
            },
          },
        };

        this.report.updateSettings(newSettings)
          .catch((error) => {
            console.log(`Error updating settings: ${error}`);
          });
      } else {
        console.log('skipping filter set because report is null');
      }
    },


    reportLoaded() {
      // we raise an event for the parent page
      this.$emit('loaded');
      this.applyFilters();

    },


    sliceyDicey() {
      // slicer tests (DEBUG)
      this.report.getPages()
        .then((pages) => {
          let myPage = null;
          pages.forEach((page) => {
            // page.hasLayout(pbi.models.LayoutType.MobilePortrait).then((hasLayout) => {
            //   console.log(hasLayout);
            // });
            if (page.name === this.reportPage) {
              myPage = page;
              console.log(`${page.name} - ${page.displayName} <- this page`);
            }
          });

          myPage.getVisuals()
            .then((visuals) => {
              visuals.forEach((visual) => {
                console.log(visual);
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    processWindowResize() {
      if (this.resizeDebounceTimeout != null) {
        clearTimeout(this.resizeDebounceTimeout);
      }

      this.resizeDebounceTimeout = setTimeout(() => {
        const width = document.getElementById(this.containerId).offsetWidth;
        const height = document.getElementById(this.containerId).offsetHeight;

        console.log(`resized! ${width} x ${height}`);

      // const newSettings = {
      //   // Settings accounting for custom layout with new size
      // };
      // visual.updateSettings(newSettings);
      }, 1000);
    },

  },

  computed: {
    // report IDs
    workspaceId() {
      return this.environment.workspaceId;
    },
    reportId() {
      return this.environment.reports.ma333.reportId;
    },
    valuePageId() {
      return this.environment.reports.ma333.valuePageId;
    },
    volumePageId() {
      return this.environment.reports.ma333.volumePageId;
    },

    currentPage() {
      return this.vt[0];
    },

    reportPage() {
      let pageId = this.valuePageId;
      if (this.currentPage === 'Volume') {
        pageId = this.volumePageId;
      }
      return pageId;
    },

    // filter arrays
    yr() {
      return this.$store.getters['year/selectedValueArray'];
    },
    vt() {
      return this.$store.getters['valueType/selectedValueArray'];
    },
    vm() {
      return this.$store.getters['valueMeasure/selectedValueArray'];
    },
    // mcs() {
    //   return this.$store.getters['global/marketClassificationsArray'];
    // },
    rs() {
      return this.$store.getters['region/selectedValueArray'];
    },

    filters() {
      const filters = [{
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'DimCalendar',
          column: 'Year',
        },
        operator: 'In',
        values: this.yr,
      }];

      // filters.push({
      //   $schema: 'http://powerbi.com/product/schema#basic',
      //   target: {
      //     table: 'FactIWSR',
      //     column: 'ValueType',
      //   },
      //   operator: 'In',
      //   values: this.vt,
      // });

      // filters.push({
      //   $schema: 'http://powerbi.com/product/schema#basic',
      //   target: {
      //     table: 'FactIWSR',
      //     column: 'ValueType',
      //   },
      //   operator: 'In',
      //   values: this.vt,
      // });

      // if (this.mcs.length > 0) {
      //   filters.push({
      //     $schema: 'http://powerbi.com/product/schema#basic',
      //     target: {
      //       table: 'DimCountry',
      //       column: 'Market Classification',
      //     },
      //     operator: 'In',
      //     values: this.mcs,
      //     filterType: pbi.models.FilterType.BasicFilter,
      //   });
      // }

      if (this.rs.length > 0) {
        filters.push({
          $schema: 'http://powerbi.com/product/schema#basic',
          target: {
            table: 'DimCountry',
            column: 'Region',
          },
          operator: 'In',
          values: this.rs,
          filterType: pbi.models.FilterType.BasicFilter,
        });
      }

      // debug code
      console.log('filters:');
      for (let i = 0; i < filters.length; i += 1) {
        console.log(`---${filters[i].target.table}---${filters[i].target.column}`);
        for (let j = 0; j < filters[i].values.length; j += 1) {
          console.log(`------${filters[i].values[j]}`);
        }
      }

      return filters;
    },


    containerId() {
      return `embeddedContent${this.reportId}`;
    },

    environment() {
      return this.$store.state.global.environment;
    },
  },

  watch: {
    vt: {
      handler() {
        console.log('detected change in value type');
        if (this.currentPage === 'Volume') {
          this.report.setPage(this.volumePageId);
        } else {
          this.report.setPage(this.valuePageId);
        }
        // debug
        this.sliceyDicey();
      },
    },
    vm: {
      handler() {
        console.log('detected change in value measure');
        this.applyFilters();
      },
    },

    // mcs: {
    //   handler(val) {
    //     console.log('detected change in market classifications');
    //   },
    // },

    filters: {
      handler() {
        console.log('report detected filter change');
        this.applyFilters();
      },
      deep: true,
    },
  },

  async mounted() {
    console.log('MOUNTED 333');
    await axios
      .post(`https://${this.environment.apiUrl}/api/token`, {
        workspaceId: this.workspaceId,
        reportId: this.reportId,
      })
      .then((response) => {
        this.pbiEmbedConfig = response.data;
      })
      .catch(error => (console.log(`Error querying API for embed config: ${error.data}`)));

    // const accessToken = this.pbiEmbedConfig.;

    console.log('AWAIT COMPLETE 333');

    // check if the page is still loaded and if not abort
    // (this is because occasionally we get here after the user has navigated to a different page)
    if (document.getElementById(this.containerId) == null) {
      return;
    }

    const { models } = pbi;

    // Get embedContainer width and height
    const pageWidth = document.getElementById(this.containerId).offsetWidth;
    const pageHeight = document.getElementById(this.containerId).offsetHeight;

    console.log(`dims: ${pageWidth} x ${pageHeight}`);

    const config = {
      // minimum params
      type: 'report',
      embedUrl: this.pbiEmbedConfig.embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: this.pbiEmbedConfig.embedToken.token,
      id: this.reportId,
      pageName: this.reportPage,

      permissions: models.Permissions.Read, // open read-only
      viewMode: models.ViewMode.View, // open in view mode
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: models.BackgroundType.Transparent,
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToPage,
          pagesLayout: {
            ReportSection76fdb174b8215a2566c4: {
              defaultLayout: {
                displayState: {
                  mode: models.VisualContainerDisplayMode.Hidden,
                },
              },
              visualsLayout: {
              },
            },
            ReportSection65002c80008ee0027449: {
              defaultLayout: {
                displayState: {
                  mode: models.VisualContainerDisplayMode.Hidden,
                },
              },
              visualsLayout: {
              },
            },
          },
        },
      },
      filters: [], // set this to programatically apply filters
    };

    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

    const embedContainer = document.getElementById(this.containerId);
    this.report = powerbi.embed(embedContainer, config);


    this.report.on('loaded', () => {
      this.reportLoaded();
    });

    this.report.on('rendered', () => {
      console.log('handled rendered');
       // debug
      this.sliceyDicey();
    });

    this.report.on('dataSelected', () => {
      console.log('handled dataSelected');
    });

    this.report.on('filtersApplied', () => {
      console.log('handled filters applied');
    });

    // add a resize event handler with debounce
    window.addEventListener('resize', this.processWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.processWindowResize);
  },


};

</script>


<style scoped>

.embedContainer {
    width: 100%;
    height: calc(0.45 * 100vw);
    background-color: white;
    padding: 0px;
    clear: both;
}
.desktop-view iframe, .mobile-view iframe {
    border: none;
}

</style>
