// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill';
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import Vuetify from 'vuetify';
import VueAnalytics from 'vue-analytics';
import 'vuetify/dist/vuetify.min.css'; // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import './assets/css/wafonts.css';


// fontawesome bits
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCoffee, faLongArrowAltUp, faLongArrowAltDown, faPhone, faEnvelope, faPrint, faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// VueLayers is used for the mapping
import VueLayers from 'vuelayers';
import store from './store/index';

import router from './router/index';
import App from './App.vue';
import 'vuelayers/lib/style.css';

// signalR used for pushed data
const signalR = require('@aspnet/signalr');

Vue.prototype.$signalR = signalR;


library.add(faCoffee, faLongArrowAltUp, faLongArrowAltDown, faPhone, faEnvelope, faPrint, faHome);
Vue.component('font-awesome-icon', FontAwesomeIcon);


Vue.config.productionTip = false;

// added signalr
Vue.use(BootstrapVue);
Vue.use(Vuetify);
Vue.use(VueLayers);
Vue.use(VueAnalytics, {
  id: 'UA-53960747-21',
  router,
});


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');
