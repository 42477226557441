<template>
  <div class="desktop-view" align="left" >
    <div :id="containerId" class="embedContainer" />
  </div>
</template>

<script>
import axios from 'axios';
import * as pbi from 'powerbi-client';

export default {
  name: 'PbiReport_MA_9Print',
  components: {
  },

  props: [
    'width',
    'height',
  ],

  data() {
    return {
      pbiEmbedConfig: '',
      report: null,
      resizeDebounceTimeout: null,
    };
  },

  methods: {
    applyFilters() {
      console.log('set report filter MA9Print');

      if (this.report != null) {
        if (this.filters.length > 0) {
          this.report.setFilters(this.filters)
            .catch((errors) => {
              console.log(errors);
            // Handle error
            });
        } else {
          console.log('clearing filter');
          this.report.removeFilters();
        }
      } else {
        console.log('skipping filter set because report is null');
      }
    },


    reportLoaded() {
      // we raise an event for the parent page
      this.$emit('loaded');
      this.applyFilters();


      // debug
      this.sliceyDicey();
    },

    reportRendered() {
      // we raise an event for the parent page
      this.$emit('rendered');
    },


    sliceyDicey() {
      // slicer tests (DEBUG)
      this.report.getPages()
        .then((pages) => {
          let myPage = null;
          pages.forEach((page) => {
            if (page.name === this.reportPage) {
              myPage = page;
              console.log(`${page.name} - ${page.displayName} <- this page`);
            }
          });

          myPage.getVisuals()
            .then((visuals) => {
              visuals.forEach((visual) => {
                console.log(visual);
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    processWindowResize() {
      if (this.resizeDebounceTimeout != null) {
        clearTimeout(this.resizeDebounceTimeout);
      }

      this.resizeDebounceTimeout = setTimeout(() => {
        const width = document.getElementById(this.containerId).offsetWidth;
        const height = document.getElementById(this.containerId).offsetHeight;

        console.log(`resized! ${width} x ${height}`);

      // const newSettings = {
      //   // Settings accounting for custom layout with new size
      // };
      // visual.updateSettings(newSettings);
      }, 1000);
    },

  },

  computed: {
    // report IDs
    workspaceId() {
      return this.environment.workspaceId;
    },
    reportId() {
      return this.environment.reports.ma9.reportId;
    },
    reportPage() {
      return this.environment.reports.ma9.pageId;
    },

    // filter arrays
    yr() {
      return this.$store.getters['year/selectedValueArray'];
    },
    // mcs() {
    //   return this.$store.getters['global/marketClassificationsArray'];
    // },
    rs() {
      return this.$store.getters['region/selectedValueArray'];
    },

    filters() {
      const filters = [{
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'DimCalendar',
          column: 'Year',
        },
        operator: 'In',
        values: this.yr,
      }];

      // if (this.mcs.length > 0) {
      //   filters.push({
      //     $schema: 'http://powerbi.com/product/schema#basic',
      //     target: {
      //       table: 'DimCountry',
      //       column: 'Market Classification',
      //     },
      //     operator: 'In',
      //     values: this.mcs,
      //     filterType: pbi.models.FilterType.BasicFilter,
      //   });
      // }

      if (this.rs.length > 0) {
        filters.push({
          $schema: 'http://powerbi.com/product/schema#basic',
          target: {
            table: 'DimCountry',
            column: 'Region',
          },
          operator: 'In',
          values: this.rs,
          filterType: pbi.models.FilterType.BasicFilter,
        });
      }

      // debug code
      console.log('filters:');
      for (let i = 0; i < filters.length; i += 1) {
        console.log(`---${filters[i].target.table}---${filters[i].target.column}`);
        for (let j = 0; j < filters[i].values.length; j += 1) {
          console.log(`------${filters[i].values[j]}`);
        }
      }

      return filters;
    },


    containerId() {
      return `embeddedContent${this.reportId}`;
    },
    environment() {
      return this.$store.state.global.environment;
    },
  },


  watch: {

    filters: {
      handler() {
        console.log('report detected filter change');
        this.applyFilters();
      },
      deep: true,
    },

  },

  async mounted() {
    await axios
      .post(`https://${this.environment.apiUrl}/api/token`, {
        workspaceId: this.workspaceId,
        reportId: this.reportId,
      })
      .then((response) => {
        this.pbiEmbedConfig = response.data;
      })
      .catch(error => (console.log(`Error querying API for embed config: ${error.data}`)));

    // const accessToken = this.pbiEmbedConfig.;

    const { models } = pbi;

    // Get embedContainer width and height
    const pageWidth = document.getElementById(this.containerId).offsetWidth;
    const pageHeight = document.getElementById(this.containerId).offsetHeight;

    console.log(`dims: ${pageWidth} x ${pageHeight}`);

    const config = {
      // minimum params
      type: 'report',
      embedUrl: this.pbiEmbedConfig.embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: this.pbiEmbedConfig.embedToken.token,
      id: this.reportId,
      pageName: this.reportPage,

      permissions: models.Permissions.Read, // open read-only
      viewMode: models.ViewMode.View, // open in view mode
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: models.BackgroundType.Transparent,
        layoutType: models.LayoutType.Custom,
        customLayout: {
          pageSize: {
            type: models.PageSizeType.Custom,
            width: 1300,
            height: 3500,
          },
          displayOption: models.DisplayOption.FitToPage,
          pagesLayout: {
            ReportSection92c1f7b0eeb287108356: {
              defaultLayout: {
                displayState: {
                  mode: models.VisualContainerDisplayMode.Visible,
                },
              },
              visualsLayout: {
                d829e39cc8e7b85edab9: { // "Comparison of Price Per Bottle (PPB)" bar chart
                  x: 0,
                  y: 0,
                  width: 1300,
                  height: 3500,
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Visible,
                  },
                },
              },
            },
          },
        },
      },
      filters: [], // set this to programatically apply filters
    };

    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

    const embedContainer = document.getElementById(this.containerId);
    this.report = powerbi.embed(embedContainer, config);


    this.report.on('loaded', () => {
      this.reportLoaded();
    });

    this.report.on('rendered', () => {
      console.log('handled rendered');

      this.reportRendered();
    });

    this.report.on('dataSelected', () => {
      console.log('handled dataSelected');
    });

    this.report.on('filtersApplied', () => {
      console.log('handled filters applied');
    });

    // add a resize event handler with debounce
    window.addEventListener('resize', this.processWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.processWindowResize);
  },


};

</script>


<style scoped>

.embedContainer {
    width: 100vw;
    height: calc(2.75 * 100vw);
    background-color: white;
    padding: 0px;
    clear: both;
}
.desktop-view iframe, .mobile-view iframe {
    border: none;
}

</style>
