<template>
  <div class="app">
    <AppHeader
      fixed
      class="brand"
    >
      <b-link
          class="navbar-brand-full"
          href="https://www.wineaustralia.com" target="blank"
          >
            <img src="img/Wine Australia Logo Short_Black.png"
                width="86"
                height="40"
                alt="Wine Australia"/>
      </b-link>

      <SidebarToggler
        class="d-lg-none"
        display="md"
        :defaultOpen="false"
        mobile
      />

      <CustomSidebarToggler
        hidden
        id="toggly"
        class="d-md-down-none"
        display="lg"
        :defaultOpen="false"
      />

      <b-navbar-nav class="ml-auto">
      </b-navbar-nav>
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item
          class="px-3"
          to="/home"
        ><font-awesome-icon icon="home" /></b-nav-item>
        <b-nav-item
          class="px-3"
          to="/explore"
        >Explore Markets</b-nav-item>
        <b-nav-item
          class="px-3"
          to="/market-summary"
        >Market Summary</b-nav-item>
        <b-nav-item
          class="px-3"
          to="/market-list"
        >Market List</b-nav-item>
        <b-nav-item
          class="px-3"
          to="/market-comparison"
        >Market Comparison</b-nav-item>
        <b-nav-item
          class="px-3"
          to="/new-products"
        > </b-nav-item>
        <b-nav-item
          class="px-3"
          to="/new-markets"
        > </b-nav-item>
      </b-navbar-nav>

      <b-link
        class="mobile-home-button"
        to="/home"
      >
        <font-awesome-icon icon="home" />
      </b-link>
      &nbsp;&nbsp;

    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
      </AppSidebar>
      <main class="main">
        <div
          id="containerDiv"
          class="container fill"
        >
          <router-view :key="$route.fullPath"></router-view>
          <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
          >
            {{ snackText }}
            <v-btn
              flat
              @click="snack = false"
            >Close</v-btn>
          </v-snackbar>

        </div>
      </main>
    </div>

    <footer>
      <b-row>
        <b-col lg="12">
          <div class="copyright">
            <div class="wrapper">
              <p>&copy; 2019&nbsp;Wine Australia.&nbsp;ABN: 89 636 749 924<br class="hidden-desktop" /> <span class="visible-desktop">&nbsp; I &nbsp;</span>
                <b-link
                  v-b-modal.modal-center
                >Disclaimer</b-link>&nbsp; I&nbsp; <a href="/assets/Glossary_Sources_EMAT.pdf" target="_blank">Data sources and glossary of terms</a>&nbsp; I&nbsp; <a
                  href="https://www.wineaustralia.com/market-insights"
                  target="_blank"
                >More market insights</a>&nbsp; I&nbsp; <a
                  href="mailto:market.insights@wineaustralia.com"
                >Contact us</a>&nbsp; I <a href="https://www.wineaustralia.com/about-us/" target="_blank">About Wine Australia</a></p>
            </div>
          </div>
        </b-col>
      </b-row>
    </footer>
    <!-- Modal Component -->
    <b-modal id="modal-center" centered title="Disclaimer" ok-only no-fade="">
      <p class="my-4">While Wine Australia makes every effort to ensure the accuracy and currency of reports, information and/or data generated by this online tool,
        we accept no responsibility for information, which may later prove to be misrepresented or inaccurate, or reliance placed on the reports, information and/or
        data by those interacting with the tool. Provisions of the Copyright Act 1968 apply to the publication and creation of reports, information and/or data and
        all other rights are reserved. For further information as to our copyright policy please see
          <a href="https://www.wineaustralia.com/copyright" target="_blank">https://www.wineaustralia.com/copyright</a>.
      </p>
    </b-modal>
  </div>
</template>

<script>
import nav from '@/_nav';
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Footer as TheFooter,
  Breadcrumb,
} from '@coreui/vue';
import CustomSidebarToggler from '@/components/widgets/CustomSidebarToggler.vue';

export default {
  name: 'Container',
  components: {
    AppHeader,
    AppSidebar,
    TheFooter,
    Breadcrumb,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    CustomSidebarToggler,
  },
  data() {
    return {
      nav: nav.items,
      showAsideBar: false,
      snack: false,
      snackColor: '',
      snackText: '',
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(route => route.name || route.meta.label);
    },
  },
  methods: {
    getData() {
      for (let i = 0; i < nav.items.length; i += 1) {
        if (nav.items[i].name === this.$route.name && this.$route.name === 'Dashboard') {
          this.showAsideBar = true;
          break;
        } else {
          this.showAsideBar = false;
        }
      }
    },
  },

  watch: {
  },

  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.brand {
  background-color: #333;
  font-family: Walsheim, Arial, Helvetica, sans-serif !important;
  font-size: 14pt;
  font-weight: 400;
  color: #bbb;
  padding-left: 10px;
}

.mobile-home-button {
  color: #bbb;
  visibility: hidden;
  width: 0px;
}

@media screen and (max-width: 991.98px) {
  .app-header .navbar-brand {
    position: absolute;
    left: 145px;
    margin-left: -77.5px;
  }
}

@media screen and (max-width: 991px) {
  .sidebar {
      position: fixed;
      z-index: 1019;
      width: 250px;
      height: calc(100vh - 40px);
      margin-top: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .mobile-home-button {
    visibility: visible;
    width: 30px;
  }
}


html:not([dir="rtl"]) .sidebar {
    margin-left: -250px;
}

li.nav-item {
  color: #fff !important;
}
.navbar-nav .nav-link {
  color: #bbb;
  font-size: 1.6rem;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #ccc;
  font-size: 1.6rem;
}
.navbar-nav .nav-link.active:hover,
.navbar-nav .nav-link.active:focus {
  color: #fff;
  font-size: 1.6rem;
}

.navbar-nav .nav-link.active {
  color: #fff;
  font-size: 1.6rem;
}

.app-header .navbar-brand {
  justify-content: left;
}

.fill {
  min-height: 95%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0px 2% 0px 0px;
  padding: 20px 2% 20px 2%;
}


/* footer {
  background-color: #eceae5;
  margin-top: 10px;
  padding: 30px 20px 10px 20px;
}

footer nav ul {
  margin: 0;
  padding: 0;
  font-size: 20px;
  list-style: none;
}

footer ul li {
  padding-left: 0;
  margin-bottom: 0;
}

footer nav ul a {
  color: #000;
  margin-bottom: 15px;
  display: inline-block;
}

@media (min-width: 1025px) {
  footer ul > li > a {
    font-size: 20px;
  }
}

@media (min-width: 1025px) {
  footer ul > li > ul > li > a {
    font-size: 14px;
  }
}

@media (min-width: 1025px) {
  footer a:hover {
    color: #66c5b8;
  }
}

@media (min-width: 768px) {
  footer ul > li > a {
    font-size: 18px;
  }
}

footer nav ul ul a {
  color: #666;
  margin-bottom: 10px;
  display: inline-block;
}

footer ul > li > a {
  font-size: 16px;
}
footer nav a {
  text-decoration: none;
}

footer {
  font-family: "Walsheim", Arial, Helvetica, sans-serif;
  font-size: 10px;
} */
</style>

<style>
html,
body {
  font-family: "Walsheim", Arial, Helvetica, sans-serif;
  font-size: 10px;
  height: 100%;
  color: #666;
}

.app-header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.app-header .navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23cccccc' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.modal-body {
  font-family: "Walsheim", Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  color: #666;
}

.modal-content {
  height: 500px;
}

.modal-title {
  font-family: "Walsheim", Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  color: #000;
}

.btn {
  font-family: "Walsheim", Arial, Helvetica, sans-serif;
  font-size: 1.6rem !important;
  font-weight: 600;
}


.sidebar .sidebar-nav {
  width: 250px !important;
}

.sidebar .nav-link {
    font-size: 1.6rem !important;
}

</style>
