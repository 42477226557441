// year filter module

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'Retail Value', label: 'Value' },
      { value: 'Volume', label: 'Volume' },
    ],
    selection: { value: 'Retail Value', label: 'Value' },

  },

  mutations: {
    updateSelection(state, year) {
      state.selection = year;
      console.log(`Selected value type: ${state.selection.value}`);
    },
  },

  getters: {
    selectedItem: state => state.selection,
    selectedValueArray: state => [state.selection.value],
  },

  actions: {

  },


};
