<template>
  <b-card header="Power BI Test Information">
    <table class="table" >
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">id</th>
          <td>{{ pbiEmbedConfig.id }}</td>
        </tr>
        <tr>
          <th scope="row">embedUrl</th>
          <td>{{ pbiEmbedConfig.embedUrl }}</td>
        </tr>
        <tr>
          <th scope="row">embedToken</th>
          <td>{{ pbiEmbedConfig.embedToken }}</td>
        </tr>
        <tr>
          <th scope="row">tokenId</th>
          <td>{{ pbiEmbedConfig.tokenId }}</td>
        </tr>
        <tr>
          <th scope="row">expiration</th>
          <td>{{ pbiEmbedConfig.expiration }}</td>
        </tr>
        <tr>
          <th scope="row">minutesToExpiration</th>
          <td>{{ pbiEmbedConfig.minutesToExpiration }}</td>
        </tr>
        <tr>
          <th scope="row">isEffectiveIdentityRolesRequired</th>
          <td>{{ pbiEmbedConfig.isEffectiveIdentityRolesRequired }}</td>
        </tr>
        <tr>
          <th scope="row">isEffectiveIdentityRequired</th>
          <td>{{ pbiEmbedConfig.isEffectiveIdentityRequired }}</td>
        </tr>
        <tr>
          <th scope="row">enableRLS</th>
          <td>{{ pbiEmbedConfig.enableRLS }}</td>
        </tr>
        <tr>
          <th scope="row">username</th>
          <td>{{ pbiEmbedConfig.username }}</td>
        </tr>
        <tr>
          <th scope="row">roles</th>
          <td>{{ pbiEmbedConfig.roles }}</td>
        </tr>
        <tr>
          <th scope="row">errorMessage</th>
          <td>{{ pbiEmbedConfig.errorMessage }}</td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PbiTest',
  components: {
  },
  data() {
    return {
      pbiEmbedConfig: '',

      // EVERYTHING BELOW IS SAMPLE
      wind: { speed: 20, direction: 120 },
      timer: '', // wind polling timer
      deviceInformation: [],
      // alarmSummary: [],
      alarmHistory: [],
      sensorData: [
        {
          sensorId: 58889, channelId: 1, timestamp: '', name: 'H₂S', value: 55, unit: 'ppm', arrow: 'up',
        },
        {
          sensorId: 88878, channelId: 1, timestamp: '', name: 'Level', value: 2920, unit: 'mm', arrow: 'none',
        },
      ],
    };
  },
  methods: {

  },

  async mounted() {
    await axios
      .get(`https://${this.environment.apiUrl}/api/token`)
      .then((response) => {
        this.pbiEmbedConfig = response.data;
      })
      .catch(error => (console.log(`Error querying API for embed config: ${error.data}`)));
  },


  computed: {
    environment() {
      return this.$store.state.global.environment;
    },
  },
};

</script>

<style>

#reportContainer {
    width: 100%;
    height: 890px;
    background-color: white;
    padding: 0px;
    clear: both;
}
.desktop-view iframe, .mobile-view iframe {
    border: none;
}

</style>
