<template>
  <div class="app-body">
    <div id="containerDiv" class="container fill print-container">
      <div id="map">
        <b-row>
          <b-img
              src="img/print-header-logo.png"
              center
              width="826"
              height="50"
              alt="Wine Australia providing insights on Australian Wine"
            />
        </b-row>
        <b-row>
          <b-col cols="12">
            &nbsp;
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            &nbsp;
          </b-col>
        </b-row>
        <b-row v-if="report==='MA33'">
          <b-col cols="12">
            <p class="heading">I'm interested in</p>
            <p class="print-value">{{ $store.getters['interestArea/selectedItem'].label }}</p>
          </b-col>
        </b-row>
        <b-row v-if="report==='MA33'">
          <b-col cols="1">
            <p class="heading">Year</p>
            <p class="print-value">{{ $store.getters['year/selectedItem'].label }}</p>
          </b-col>
          <b-col v-if="valueTypeVisible" cols="2">
            <p class="heading">Measures</p>
            <p class="print-value">{{ $store.getters['valueType/selectedItem'].label }}</p>
          </b-col>
          <b-col v-if="valueTypeVisible" cols="2">
            <p class="heading">Reporting period</p>
            <p class="print-value">{{ $store.getters['valueMeasure/selectedItem'].label }}</p>
          </b-col>
          <!-- <b-col lg="3">
            <p class="heading">Market classification</p>
            <p class="print-value">{{ marketClassifications }}</p>
          </b-col> -->
          <b-col cols="2">
            <p class="heading">Region</p>
            <p class="print-value">{{ regionsPrintLabel }}</p>
          </b-col>
        </b-row>

        <b-row v-if="report==='MA1' || report==='MA2'">
          <b-col cols="3">
            <p class="heading">Variety</p>
            <p class="print-value">{{ varietiesPrintLabel }}</p>
          </b-col>
          <b-col cols="3">
            <p class="heading">GI Region</p>
            <p class="print-value">{{ giRegionsPrintLabel }}</p>
          </b-col>
          <b-col cols="3">
            <p class="heading">FOB Price Per Litre (AUD)</p>
            <p class="print-value">{{ fobPricePerLitresPrintLabel }}</p>
          </b-col>
          <b-col cols="3">
            <p class="heading">Year</p>
            <p class="print-value">{{ $store.getters['year/selectedItem'].label }}</p>
          </b-col>
        </b-row>
        <b-row v-if="report==='MA1' || report==='MA2'">
          <b-col cols="3">
            <p class="heading">Wine Style</p>
            <p class="print-value">{{ wineStylesPrintLabel }}</p>
          </b-col>
          <b-col cols="3">
            <p class="heading">Container</p>
            <p class="print-value">{{ containersPrintLabel }}</p>
          </b-col>
          <b-col v-if="report==='MA1'" cols="3">
            <p class="heading">Market</p>
            <p class="print-value">{{ marketsPrintLabel }}</p>
          </b-col>
          <b-col cols="1">
            <p class="heading">Year Ending</p>
            <p class="print-value">{{ $store.getters['yearEnding/selectedItem'].label }}</p>
          </b-col>
          <b-col cols="2">
            <p class="heading">&nbsp;</p>
          </b-col>
        </b-row>

        <b-row v-if="report==='MA9'">
          <b-col cols="1">
            <p class="heading">Year</p>
            <p class="print-value">{{ $store.getters['year/selectedItem'].label }}</p>
          </b-col>
          <b-col cols="2">
            <p class="heading">Region</p>
            <p class="print-value">{{ regionsPrintLabel }}</p>
          </b-col>
          <b-col cols="9">
            <p class="heading">&nbsp;</p>
          </b-col>
        </b-row>

        <b-row v-if="report === 'MA10' || report === 'MA11'">
          <b-col cols="12">
            <p class="heading">&nbsp;</p>
          </b-col>
        </b-row>

        <b-row v-if="report==='MA33'">
          <b-col cols="12">
            <p class="heading">&nbsp;</p>
          </b-col>
        </b-row>

        <b-row >
          <b-col cols="12" >
            <div>

            <MA1Print
              v-if="pageLoaded && report==='MA1'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA2Print
              v-if="pageLoaded && report==='MA2'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA9Print
              v-if="pageLoaded && report==='MA9'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA10Print
              v-if="pageLoaded && report==='MA10'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA11Print
              v-if="pageLoaded && report==='MA11'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA331Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-1'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA332Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-2'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA333Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-3'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA334Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-4'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA335Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-5'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA336Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-6'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA337Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-7'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            <MA338Print
              v-if="pageLoaded && report==='MA33' && $store.getters['interestArea/selectedItem'].value==='MA-33-8'"
              @loaded= "reportLoaded()"
              @rendered= "reportRendered()"
            />

            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <div class="copyright">
              <div class="wrapper">
                <p class="heading">Disclaimer</p>
                <p>This information has been made available to assist on the understanding that the Wine Australia is not
                  rendering professional advice. Wine Australia does not accept responsibility for the results of any actions
                  taken on the basis of the information contained in this report nor for the accuracy, currency or completeness
                  of any material contained in it. Wine Australia expressly disclaims all and any liability and responsibility
                  to any person in respect of consequences of anything done in respect of reliance, whether wholly or in part,
                  upon this report.Copyright in all data provided to your organisation is owned by Wine Australia unless
                  otherwise indicated.  You may store, display, print and reproduce this material only for non-commercial use
                  or use within your organisation so long as you give appropriate acknowledgement to the copyright owner.
                  The data must not be shared with commercial third-parties without written consent from Wine Australia.
                  More information can be found on our website www.wineaustralia.com.</p>
                <p>&copy; 2019&nbsp;Wine Australia&nbsp;</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import MA1Print from '@/components/reports/MA1Print.vue';
import MA2Print from '@/components/reports/MA2Print.vue';
import MA9Print from '@/components/reports/MA9Print.vue';
import MA10Print from '@/components/reports/MA10Print.vue';
import MA11Print from '@/components/reports/MA11Print.vue';
import MA331Print from '@/components/reports/MA331Print.vue';
import MA332Print from '@/components/reports/MA332Print.vue';
import MA333Print from '@/components/reports/MA333Print.vue';
import MA334Print from '@/components/reports/MA334Print.vue';
import MA335Print from '@/components/reports/MA335Print.vue';
import MA336Print from '@/components/reports/MA336Print.vue';
import MA337Print from '@/components/reports/MA337Print.vue';
import MA338Print from '@/components/reports/MA338Print.vue';
import vSelect from '@/components/widgets/CustomSelect/components/CustomSelect.vue';


export default {
  name: 'Print',
  components: {
    MA1Print,
    MA2Print,
    MA9Print,
    MA10Print,
    MA11Print,
    MA331Print,
    MA332Print,
    MA333Print,
    MA334Print,
    MA335Print,
    MA336Print,
    MA337Print,
    MA338Print,
    vSelect,
  },
  data() {
    return {
      pbiEmbedConfig: '',
      // marketClassifications: [],

      // filters
      regions: [],
      varieties: [],
      giRegions: [],
      fobPricePerLitres: [],
      wineStyles: [],
      containers: [],
      markets: [],

      pageLoaded: false,
    };
  },
  watch: {
    // selectedMarketClassifications(val) {
    //   // const filters = [];
    //   // for (let i = 0; i < val.length; i += 1) {
    //   //   console.log(val[i].value);
    //   //   filters.push(val[i].value);
    //   // }
    //   // this.filter.values = filters;
    // },

  },

  methods: {
    print() {
      console.log('print method called');
      window.print();
      // setTimeout(() => { this.$router.go(-1); }, 100);
      setTimeout(() => { window.close(); }, 100);
    },

    reportLoaded() {
      console.log('report loaded called');

      this.processWindowResize();
    },

    reportRendered() {
      console.log('report rendered - printing');
      this.print();
    },

    processWindowResize() {
      if (this.resizeDebounceTimeout != null) {
        clearTimeout(this.resizeDebounceTimeout);
      }

      this.resizeDebounceTimeout = setTimeout(() => {
        const width = document.getElementById('containerDiv').offsetWidth;
        const height = document.getElementById('containerDiv').offsetHeight;

        console.log(`page resized! ${width} x ${height}`);
        console.log(`isMobile = : ${width < 992}`);

        this.isMobile = width < 992;

      // const newSettings = {
      //   // Settings accounting for custom layout with new size
      // };
      // visual.updateSettings(newSettings);
      }, 1000);
    },

  },

  mounted() {
    switch (this.$route.params.report) {
      case 'MA-33-1':
        this.setActiveInterestAreaByIndex(0);
        break;
      case 'how-does-the-average-wine-price-per-bottle-compare-across-markets':
        this.setActiveInterestAreaByIndex(1);
        break;
      case 'which-markets-consume-the-most-imported-wine':
        this.setActiveInterestAreaByIndex(2);
        break;
      case 'what-is-the-share-of-on-versus-off-premise-in-each-market':
        this.setActiveInterestAreaByIndex(3);
        break;
      case 'how-much-wine-consumed-in-each-market-is-premium':
        this.setActiveInterestAreaByIndex(4);
        break;
      case 'what-is-wine-share-of-the-total-alcohol-market':
        this.setActiveInterestAreaByIndex(5);
        break;
      case 'where-is-australian-wine-exported-to':
        this.setActiveInterestAreaByIndex(6);
        break;
      case 'where-are-australian-wines-exported-to-by-price-point':
        this.setActiveInterestAreaByIndex(7);
        break;

      default:
    }

    const width = document.getElementById('containerDiv').offsetWidth;
    const height = document.getElementById('containerDiv').offsetHeight;

    console.log(`page resized! ${width} x ${height}`);
    console.log(`isMobile = : ${width < 992}`);

    this.isMobile = width < 992;


    // add a resize event handler with debounce
    window.addEventListener('resize', this.processWindowResize);

    // filters
    this.regions = [...this.$store.getters['region/selectedItems']];
    this.varieties = [...this.$store.getters['variety/selectedItems']];
    this.giRegions = [...this.$store.getters['giRegion/selectedItems']];
    this.fobPricePerLitres = [...this.$store.getters['fobPricePerLitre/selectedItems']];
    this.wineStyles = [...this.$store.getters['wineStyle/selectedItems']];
    this.containers = [...this.$store.getters['container/selectedItems']];
    this.markets = [...this.$store.getters['market/selectedItems']];

    this.pageLoaded = true;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.processWindowResize);
  },


  computed: {
    report() {
      return this.$route.params.report;
    },

    valueTypeVisible() {
      switch (this.$store.getters['interestArea/selectedItem'].value) {
        case 'MA-33-2':
        case 'MA-33-4':
        case 'MA-33-6':
          return false;
        default:
          return true;
      }
    },

    regionsPrintLabel() {
      let label = 'All';
      if (this.regions.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator.concat(`${currentValue.label}, `);
        label = this.regions.reduce(reducer, '');
        label = label.trim().slice(0, -1);
      }
      return label;
    },

    varietiesPrintLabel() {
      let label = 'All';
      if (this.varieties.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator.concat(`${currentValue.label}, `);
        label = this.varieties.reduce(reducer, '');
        label = label.trim().slice(0, -1);
      }
      return label;
    },

    giRegionsPrintLabel() {
      let label = 'All';
      if (this.giRegions.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator.concat(`${currentValue.label}, `);
        label = this.giRegions.reduce(reducer, '');
        label = label.trim().slice(0, -1);
      }
      return label;
    },

    fobPricePerLitresPrintLabel() {
      let label = 'All';
      if (this.fobPricePerLitres.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator.concat(`${currentValue.label}, `);
        label = this.fobPricePerLitres.reduce(reducer, '');
        label = label.trim().slice(0, -1);
      }
      return label;
    },

    wineStylesPrintLabel() {
      let label = 'All';
      if (this.wineStyles.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator.concat(`${currentValue.label}, `);
        label = this.wineStyles.reduce(reducer, '');
        label = label.trim().slice(0, -1);
      }
      return label;
    },

    containersPrintLabel() {
      let label = 'All';
      if (this.containers.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator.concat(`${currentValue.label}, `);
        label = this.containers.reduce(reducer, '');
        label = label.trim().slice(0, -1);
      }
      return label;
    },

    marketsPrintLabel() {
      let label = 'All';
      if (this.markets.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator.concat(`${currentValue.label}, `);
        label = this.markets.reduce(reducer, '');
        label = label.trim().slice(0, -1);
      }
      return label;
    },

    environment() {
      return this.$store.state.global.environment;
    },

  },
};

</script>


<style scoped>

p.heading {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.7rem;
    font-weight: 400;
}

p.print-value {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.7rem;
    font-weight: 600;
}

.list-group-item {
  border: 0 none !important;
}

#map {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

/* .marked {
  min-height: 1000px;
  height: 1000px;
} */


</style>

<style>

.print-container {
  padding: 0px;
}

.v-select .dropdown-toggle .clear {
  visibility: hidden;
}

.v-select .selected-tag {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #888 !important;
}

.v-select ::-webkit-input-placeholder {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media (max-width: 992) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media (max-width: 992) {
  ul.dropdown-menu {
    font-size: 16px !important;
  }

}
</style>
