// year ending filter module

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'January', label: 'January' },
      { value: 'February', label: 'February' },
      { value: 'March', label: 'March' },
      { value: 'April', label: 'April' },
      { value: 'May', label: 'May' },
      { value: 'June', label: 'June' },
      { value: 'July', label: 'July' },
      { value: 'August', label: 'August' },
      { value: 'September', label: 'September' },
      { value: 'October', label: 'October' },
      { value: 'November', label: 'November' },
      { value: 'December', label: 'December' },
    ],

    // modify the set below to limit the months available for the
    // latest year of data
    optionsLatestYear: [
      { value: 'January', label: 'January' },
      { value: 'February', label: 'February' },
      { value: 'March', label: 'March' },
     // { value: 'April', label: 'April' },
     // { value: 'May', label: 'May' },
     // { value: 'June', label: 'June' },
     // { value: 'July', label: 'July' },
     //{ value: 'August', label: 'August' },
     // { value: 'September', label: 'September' },
     // { value: 'October', label: 'October' },
     // { value: 'November', label: 'November' },
     // { value: 'December', label: 'December' },
    ],

    // modify the value below to set the default drop-down value
    selection: { value: 'March', label: 'March' },

    // modify the value below to reflect the latest year of data
    latestYear: 2023,

  },

  mutations: {
    updateSelection(state, year) {
      state.selection = year;
      console.log(`Selected year ending: ${state.selection.value}`);
    },
  },

  getters: {
    selectedItem: state => state.selection,
    selectedValueArray: state => [state.selection.value],
  },

  actions: {

  },


};
