<!--
Full Screen Power BI report, primarily used for Data Crusher reports.
This component requires a single report id as it renders it full screen
for both desktop and mobile.

This template supports powerbi printing functionality and has a print button overlay
only for desktop views.

Assets for this template are stored in `public/img/FullScreenReport`
-->
<template>
  <div>
    <div id="desktop-overlay">
      <div id="print-button-container">
        <button
          id="print-info-button"
          class="print-button"
          @click="togglePrintInfo"
        >
          How to print?
        </button>
        <button id="print-button" class="print-button" @click="print">
          Print
        </button>
      </div>

      <div id="print-info-popup">
        <div id="print-info-img"></div>
        <div id="print-info-selection-panel">
          <button
            id="print-info-close-button"
            class="print-button"
            @click="togglePrintInfo"
          >
            X
          </button>
          <div id="print-info-buttons">
            <button
              class="print-button"
              @click="
                setPrintInfo(
                  'url(/img/FullScreenReport/print-popup/chrome-info.png)'
                )
              "
            >
              <img
                src="img/FullScreenReport/print-popup/chrome-icon.png"
                alt=""
              />
            </button>
            <button
              class="print-button"
              @click="
                setPrintInfo(
                  'url(/img/FullScreenReport/print-popup/firefox-info.png)'
                )
              "
            >
              <img
                src="img/FullScreenReport/print-popup/firefox-icon.png"
                alt=""
              />
            </button>
            <button
              class="print-button"
              @click="
                setPrintInfo(
                  'url(/img/FullScreenReport/print-popup/safari-info.png)'
                )
              "
            >
              <img
                src="img/FullScreenReport/print-popup/safari-icon.png"
                alt=""
              />
            </button>
            <button
              class="print-button"
              @click="
                setPrintInfo(
                  'url(/img/FullScreenReport/print-popup/edge-info.png)'
                )
              "
            >
              <img
                src="img/FullScreenReport/print-popup/edge-icon.png"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="report"></div>
  </div>
</template>

<script>
import axios from "axios";
import * as pbi from "powerbi-client";

export default {
  computed: {
    environment() {
      return this.$store.state.global.environment;
    },
    workspaceId() {
      return this.environment.workspaceId;
    }
  },

  created() {
    document.title = this.$route.name;
  },

  props: {
    reportId: String
  },

  data() {
    return {
      report: null
    };
  },

  async mounted() {
    // get token for power bi client
    const response = await axios.post(
      `https://${this.environment.apiUrl}/api/token`,
      {
        workspaceId: this.workspaceId,
        reportId: this.reportId
      }
    );
    const pbiEmbedConfig = response.data;

    // set up reports
    const powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    const container = document.getElementById("report");

    const { models } = pbi;
    const device = window.innerWidth < 768 ? "mobile" : "desktop";
    const settings =
      device === "mobile"
        ? {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
            layoutType: models.LayoutType.MobilePortrait
          }
        : {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth
            }
          };

    const config = {
      type: "report",
      embedUrl: pbiEmbedConfig.embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: pbiEmbedConfig.embedToken.token,
      permissions: models.Permissions.Read,
      viewMode: models.ViewMode.View,
      settings: settings
    };

    this.report = powerbi.embed(container, config);

    // gather printing elements so we can use in functions later
    this.printInfo = document.getElementById("print-info-popup");
    this.printInfoImg = document.getElementById("print-info-img");
  },

  methods: {
    print() {
      this.report.print();
    },
    togglePrintInfo() {
      if (this.printInfo.className === "show") {
        this.printInfo.className = "";
      } else {
        this.printInfo.className = "show";
      }
    },
    setPrintInfo(url) {
      this.printInfoImg.style.backgroundImage = url;
    }
  }
};
</script>

<style>
iframe {
  border: none;
}

#report {
  height: 100vh;

  /* Stretch the report a bit over the right and left edges
  so that the odd gap is hidden*/
  margin-right: -2vw;
}

#desktop-overlay {
  visibility: hidden;
  display: flex;
  justify-content: center;
}

#print-button-container {
  position: fixed;
  right: 0.8vw;
  padding: 5px;
  top: 0;
  overflow: hidden;
}

.print-button {
  background: #3f3f3f;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0.5vh 1vw;
  font-size: 1vw;
}

#print-info-popup {
  background-color: #333333;
  visibility: hidden;
  position: absolute;
  top: -100vh;
  opacity: 0;
  width: 70vw;
  height: 80vh;
  display: flex;
  transition: all 1s;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 30px #000000);
}

#print-info-popup.show {
  visibility: visible;
  opacity: 1;
  top: 10vh;
  transition: all 1s;
}

#print-info-img {
  background-image: url(/img/FullScreenReport/print-popup/chrome-info.png);
  background-position: center;
  background-size: contain;
  width: 90%;
  height: 100%;
}

#print-info-selection-panel {
  min-width: 10%;
  max-width: 11%;
  height: 100%;
}

#print-info-close-button {
  background: rgb(204, 0, 0);
  width: 100%;
  top: 0;
}

#print-info-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
}

@media only screen and (min-width: 768px) {
  #desktop-overlay {
    visibility: visible;
  }
}
</style>
