var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"desktop-overlay"}},[_c('div',{attrs:{"id":"print-button-container"}},[_c('button',{staticClass:"print-button",attrs:{"id":"print-info-button"},on:{"click":_vm.togglePrintInfo}},[_vm._v("\n        How to print?\n      ")]),_vm._v(" "),_c('button',{staticClass:"print-button",attrs:{"id":"print-button"},on:{"click":_vm.print}},[_vm._v("\n        Print\n      ")])]),_vm._v(" "),_c('div',{attrs:{"id":"print-info-popup"}},[_c('div',{attrs:{"id":"print-info-img"}}),_vm._v(" "),_c('div',{attrs:{"id":"print-info-selection-panel"}},[_c('button',{staticClass:"print-button",attrs:{"id":"print-info-close-button"},on:{"click":_vm.togglePrintInfo}},[_vm._v("\n          X\n        ")]),_vm._v(" "),_c('div',{attrs:{"id":"print-info-buttons"}},[_c('button',{staticClass:"print-button",on:{"click":function($event){_vm.setPrintInfo(
                'url(/img/FullScreenReport/print-popup/chrome-info.png)'
              )}}},[_c('img',{attrs:{"src":"img/FullScreenReport/print-popup/chrome-icon.png","alt":""}})]),_vm._v(" "),_c('button',{staticClass:"print-button",on:{"click":function($event){_vm.setPrintInfo(
                'url(/img/FullScreenReport/print-popup/firefox-info.png)'
              )}}},[_c('img',{attrs:{"src":"img/FullScreenReport/print-popup/firefox-icon.png","alt":""}})]),_vm._v(" "),_c('button',{staticClass:"print-button",on:{"click":function($event){_vm.setPrintInfo(
                'url(/img/FullScreenReport/print-popup/safari-info.png)'
              )}}},[_c('img',{attrs:{"src":"img/FullScreenReport/print-popup/safari-icon.png","alt":""}})]),_vm._v(" "),_c('button',{staticClass:"print-button",on:{"click":function($event){_vm.setPrintInfo(
                'url(/img/FullScreenReport/print-popup/edge-info.png)'
              )}}},[_c('img',{attrs:{"src":"img/FullScreenReport/print-popup/edge-icon.png","alt":""}})])])])])]),_vm._v(" "),_c('div',{attrs:{"id":"report"}})])}
var staticRenderFns = []

export { render, staticRenderFns }