// year filter module - used for Explore Market pages

export default {
  namespaced: true,

  state: {
    options: [
      { value: 2022, label: '2022' },
      { value: 2021, label: '2021' },
      { value: 2020, label: '2020' },
      { value: 2019, label: '2019' },
      { value: 2018, label: '2018' },
      { value: 2017, label: '2017' },
    //  { value: 2016, label: '2016' },
    // { value: 2015, label: '2015' },
    // { value: 2014, label: '2014' },
    ],

    options2019: [
      { value: 2022, label: '2022' },
      { value: 2021, label: '2021' },
      { value: 2020, label: '2020' },
      { value: 2019, label: '2019' },
      { value: 2018, label: '2018' },
      { value: 2017, label: '2017' },
      { value: 2016, label: '2016' },
      { value: 2015, label: '2015' },
      { value: 2014, label: '2014' },
    //  { value: 2013, label: '2013' },
    // { value: 2012, label: '2012' },
    // { value: 2011, label: '2011' },
    ],

    options2016: [
      { value: 2022, label: '2022' },
      { value: 2021, label: '2021' },
      { value: 2020, label: '2020' },
      { value: 2019, label: '2019' },
    //  { value: 2018, label: '2018' },
    // { value: 2017, label: '2017' },
    // { value: 2016, label: '2016' },
    ],

    options2017: [
      { value: 2022, label: '2022' },
      { value: 2021, label: '2021' },
      { value: 2020, label: '2020' },
      { value: 2019, label: '2019' },
      { value: 2018, label: '2018' },
      { value: 2017, label: '2017' },
    //  { value: 2016, label: '2016' },
    // { value: 2015, label: '2015' },
    // { value: 2014, label: '2014' },
    ],

    selection: { value: 2022, label: '2022' },

  },

  mutations: {
    updateSelection(state, year) {
      state.selection = year;
      console.log(`Selected year: ${state.selection.value}`);
    },
  },

  getters: {
    selectedItem: state => state.selection,
    selectedValueArray: state => [state.selection.value],
  },

  actions: {

  },


};
