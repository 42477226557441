<template>
  <div id="map">
    <b-row>

      <!-- These are on mobile -->
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">Variety</p>
        <v-select @input="setActiveVarieties" :options="$store.state.variety.options" :value="varieties" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">GI Region</p>
        <v-select @input="setActiveGiRegions" :options="$store.state.giRegion.options" :value="giRegions" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">FOB Price Per Litre (AUD)</p>
        <v-select @input="setActiveFobPricePerLitres" :options="$store.state.fobPricePerLitre.options" :value="fobPricePerLitres" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">Wine Style</p>
        <v-select @input="setActiveWineStyles" :options="$store.state.wineStyle.options" :value="wineStyles" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">Container</p>
        <v-select @input="setActiveContainers" :options="$store.state.container.options" :value="containers" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">Market (*required field)</p>
        <v-select @input="setActiveMarkets" :options="$store.state.market.options" :value="markets" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">Year</p>
        <v-select @input="setActiveStickyYear" :options="stickyYearOptions" :value="$store.getters['stickyYear/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <b-col class="d-block d-lg-none" lg="12">
        <p class="heading">Year Ending</p>
        <v-select @input="setActiveYearEnding" :options="yearEndingOptions" :value="$store.getters['yearEnding/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>

      <!-- These are on large -->
      <b-col class="d-none d-lg-block" lg="3">
        <p class="heading">Variety</p>
        <v-select @input="setActiveVarieties" :options="$store.state.variety.options" :value="varieties" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-none d-lg-block" lg="3">
        <p class="heading">GI Region</p>
        <v-select @input="setActiveGiRegions" :options="$store.state.giRegion.options" :value="giRegions" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-none d-lg-block" lg="3">
        <p class="heading">FOB Price Per Litre (AUD)</p>
        <v-select @input="setActiveFobPricePerLitres" :options="$store.state.fobPricePerLitre.options" :value="fobPricePerLitres" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-none d-lg-block" lg="2">
        <p class="heading">Year</p>
        <v-select @input="setActiveStickyYear" :options="stickyYearOptions" :value="$store.getters['stickyYear/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <b-col lg="1">
        <p class="heading">&nbsp;</p>
        <!-- <p class="heading text-right d-none d-lg-block">
        <b-link class="heading" to="/print/MA1" target="_blank" style="color: #777" >
          <font-awesome-icon icon="print" />
        </b-link>
        </p> -->
      </b-col>
      <b-col class="d-none d-lg-block" lg="3">
        <p class="heading">Wine Style</p>
        <v-select @input="setActiveWineStyles" :options="$store.state.wineStyle.options" :value="wineStyles" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-none d-lg-block" lg="3">
        <p class="heading">Container</p>
        <v-select @input="setActiveContainers" :options="$store.state.container.options" :value="containers" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-none d-lg-block" lg="3">
        <p class="heading">Market (*required field)</p>
        <v-select @input="setActiveMarkets" :options="$store.state.market.options" :value="markets" multiple :searchable="true" placeholder="All"></v-select>
      </b-col>
      <b-col class="d-none d-lg-block" lg="2">
        <p class="heading">Year Ending</p>
        <v-select @input="setActiveYearEnding" :options="yearEndingOptions" :value="$store.getters['yearEnding/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <b-col lg="1">
        <p class="heading">&nbsp;</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        &nbsp;
      </b-col>
    </b-row>
    <b-row >
      <b-col lg="9" >
        <div>
        <p v-if="rendering">
          Loading report...
        </p>
        <MA1 v-if="pageLoaded"
          @loaded= "reportLoaded()"
          @rendering="reportRendering()"
          @rendered="reportRendered()"
        />
        </div>
      </b-col>
      <b-col lg="3" >
        <br />
        <br />
        <br />
        <h3>Same Market, New Products</h3>
        <br />
        <p>Using export data collated through the Wine Australia Licensing and Approval System (WALAS), you can identify new product opportunities within a selected export market. Use this table as a starting point to help identify what products are exported to the chosen market, plus the growth or decline over time of those products.</p>
        <p>Step 1: Enter in the chosen market in the filter above.</p>
        <p>Step 2: Select the Variety, GI Region and FOB Price. An algorithm has been applied to these filters to find the best match.</p>
        <p>Step 3: Further narrow the search by selecting a Wine Style and Container type. These filters do not impact the algorithm.</p>
        <p>Step 4: Choose the desired year ending time period (Year and Year Ending month filters above).</p>
        <p>100% match - All three filters (Variety, GI Region and FOB Price Per Litre) match to Australian wines exported during the period selected.</p>
        <p>80%, 60% match - Identifies other export opportunities, by altering one or two components of the product identified in the filters. The variations are denoted in red.</p>
        <p>Note on Short and Long Term Growth - To remove large variations in growth rates, generally due to calculations made off a small base, results have been restricted to display +/-100% where percentage changes exceed +/-100%. Blank cells appear where no data exists in the time period being compared to. </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MA1 from '@/components/reports/MA1.vue';
import vSelect from '@/components/widgets/CustomSelect/components/CustomSelect.vue';

export default {
  name: 'NewProducts',
  components: {
    MA1,
    vSelect,
  },
  data() {
    return {
      pbiEmbedConfig: '',

      // filters
      varieties: [],
      giRegions: [],
      fobPricePerLitres: [],
      wineStyles: [],
      containers: [],
      markets: [],

      pageLoaded: false,
      rendering: false,
    };
  },

  watch: {
  },

  methods: {
    printClicked() {
      console.log('print clicked');
      window.print();
    },

    setActiveStickyYear(val) {
      console.log(`set sticky year: ${val.value}`);
      if (val != null) {
        this.$store.commit('stickyYear/updateSelection', val);
      }

      // commented out pending Martin's changes later this sprint (19/2)
      // if (val.value === 2018) {
      //   if (this.$store.getters['yearEnding/selectedItem'].value === 'October'
      //       || this.$store.getters['yearEnding/selectedItem'].value === 'November'
      //       || this.$store.getters['yearEnding/selectedItem'].value === 'December'
      //   ) {
      //     // this is where we trim to available months for the present year
      //     console.log('trimming to December 2018');
      //     this.setActiveYearEnding({ value: 'December', label: 'December' });
      //   }
      // }
    },

    setActiveYearEnding(val) {
      console.log(`set year ending: ${val.value}`);
      if (val != null) {
        this.$store.commit('yearEnding/updateSelection', val);
      }
    },

    setActiveVarieties(val) {
      console.log(`set varieties: ${val.length}`);
      this.$store.commit('variety/updateSelections', val);
    },

    setActiveGiRegions(val) {
      console.log(`set gi regions: ${val.length}`);
      this.$store.commit('giRegion/updateSelections', val);
    },

    setActiveFobPricePerLitres(val) {
      console.log(`set fob price per litre: ${val.length}`);
      this.$store.commit('fobPricePerLitre/updateSelections', val);
    },

    setActiveWineStyles(val) {
      console.log(`set wine styles: ${val.length}`);
      this.$store.commit('wineStyle/updateSelections', val);
    },

    setActiveContainers(val) {
      console.log(`set containers: ${val.length}`);
      this.$store.commit('container/updateSelections', val);
    },

    setActiveMarkets(val) {
      console.log(`set markets: ${val.length}`);
      this.$store.commit('market/updateSelections', val);
    },


    reportLoaded() {
      console.log('NewProducts (MA1) report loaded called');

      // commented out pending Martin's changes later this sprint (19/2)
      // if (this.$store.getters['stickyYear/selectedItem'].value === 2018) {
      //   if (this.$store.getters['yearEnding/selectedItem'].value === 'October'
      //       || this.$store.getters['yearEnding/selectedItem'].value === 'November'
      //       || this.$store.getters['yearEnding/selectedItem'].value === 'December'
      //   ) {
      //     // this is where we trim to available months for the present year
      //     console.log('trimming to December 2018');
      //     this.setActiveYearEnding({ value: 'December', label: 'December' });
      //   }
      // }
    },

    reportRendering() {
      console.log('MA1 reportRendering())');
      this.rendering = true;
    },

    reportRendered() {
      console.log('MA1 reportRendered())');
      this.rendering = false;
    },
  },

  mounted() {
    // filters
    this.varieties = [...this.$store.getters['variety/selectedItems']];
    this.giRegions = [...this.$store.getters['giRegion/selectedItems']];
    this.fobPricePerLitres = [...this.$store.getters['fobPricePerLitre/selectedItems']];
    this.wineStyles = [...this.$store.getters['wineStyle/selectedItems']];
    this.containers = [...this.$store.getters['container/selectedItems']];
    this.markets = [...this.$store.getters['market/selectedItems']];

    this.pageLoaded = true;
  },


  computed: {
    stickyYearOptions() {
      return this.$store.state.stickyYear.options;
    },

    yearEndingOptions() {
      switch (this.$store.getters['stickyYear/selectedItem'].value) {
        case 2023:
          return this.$store.state.yearEnding.optionsLatestYear;

        default:
          return this.$store.state.yearEnding.options;
      }
    },

    environment() {
      return this.$store.state.global.environment;
    },
  },
};

</script>


<style scoped>

p.heading {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.7rem;
    font-weight: 400;
}

p {
  font-size: 1.4rem;
}

.list-group-item {
  border: 0 none !important;
}

#map {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

/* .marked {
  min-height: 1000px;
  height: 1000px;
} */

</style>

<style>


</style>
