<template>
  <div >

      <!-- Heading Row -->
      <b-row class="panel">
        <b-col lg="4" class="panel">
          <div class="image" style="background-image: url('https://wineaustralia.com/getmedia/b50c5038-fc7b-4989-b8f5-5faa822f12c6/Hunter768.jpg?width=768&amp;height=280&amp;ext=.jpg')"></div>
        </b-col>
        <b-col lg="8">
          <p class="heading">Welcome to the Market Explorer</p>
          <p>&nbsp;</p>
          <p class="body">The Market Explorer is a business planning tool that allows users to analyse various export markets by looking at aggregated datasets pulling in economic, demographic, consumption, Australian wine exports and some behaviour data.</p>
          <p>&nbsp;</p>
          <p class="body">The tool does not attempt to prescribe which markets are the most attractive but rather provide businesses, either looking to export Australian wine or currently exporting, to analyse markets based on what is most relevant to their business requirements. For example, one business wants to be a pioneer and only export to markets with a low Australian share of wine consumption while another business only wants to send exports to a market where they don’t have to change their domestic labelling.</p>
          <p>&nbsp;</p>
          <p class="body">To get started simply follow the questions below or choose your reports through the menu above.</p>
          <p>&nbsp;</p>
          <p class="body"> 'How to' videos and useful resources can be found below along with more market insights.</p>
        </b-col>
        <!-- /.col-md-4 -->
      </b-row>
      <!-- /.row -->

      <!-- Call to Action Well -->
      <!-- <div class="card text-white bg-secondary my-4 text-center">
        <div class="card-body">
          <p class="text-white m-0">This call to action card is a great place to showcase some important information or display a clever tagline!</p>
        </div>
      </div> -->

      <!-- Content Row -->
      <b-row>
        <b-col>
          <p>&nbsp;</p>
          <p class="heading">Get started</p>
        </b-col>
      </b-row>


      <b-row>

        <!-- This one is for mobiles -->
        <b-col class="d-block d-sm-none" xs="12">
          <div class="margin-top"></div>
          <router-link to="explore/which-markets-drink-the-most-wine" class="square-text">Explore the wine export markets</router-link>
          <div class="square-img square-img-1">
            <div class="overlay"></div>
          </div>
        </b-col>

        <!-- The rest show on everything else -->
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/which-markets-drink-the-most-wine" class="square-text">{{ $store.state.interestArea.options[0].label }}</router-link>
          <div class="square-img square-img-1">
            <div class="overlay"></div>
          </div>
        </b-col>
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/how-does-the-average-wine-price-per-bottle-compare-across-markets" class="square-text">{{ $store.state.interestArea.options[1].label }}</router-link>
          <div class="square-img square-img-2">
            <div class="overlay"></div>
          </div>
        </b-col>
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/which-markets-consume-the-most-imported-wine" class="square-text">{{ $store.state.interestArea.options[2].label }}</router-link>
          <div class="square-img square-img-3">
            <div class="overlay"></div>
          </div>
        </b-col>
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/what-is-the-share-of-on-versus-off-premise-in-each-market" class="square-text">{{ $store.state.interestArea.options[3].label }}</router-link>
          <div class="square-img square-img-4">
            <div class="overlay"></div>
          </div>
        </b-col>
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/how-much-wine-consumed-in-each-market-is-premium" class="square-text">{{ $store.state.interestArea.options[4].label }}</router-link>
          <div class="square-img square-img-5">
            <div class="overlay"></div>
          </div>
        </b-col>
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/what-is-wine-share-of-the-total-alcohol-market" class="square-text">{{ $store.state.interestArea.options[5].label }}</router-link>
          <div class="square-img square-img-6">
            <div class="overlay"></div>
          </div>
        </b-col>
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/where-is-australian-wine-exported-to" class="square-text">{{ $store.state.interestArea.options[6].label }}</router-link>
          <div class="square-img square-img-7">
            <div class="overlay"></div>
          </div>
        </b-col>
        <b-col class="d-none d-sm-block" xs="12" sm="6" md="6" lg="4" xl="3">
          <div class="margin-top"></div>
          <router-link to="explore/where-are-australian-wines-exported-to-by-price-point" class="square-text">{{ $store.state.interestArea.options[7].label }}</router-link>
          <div class="square-img square-img-8">
            <div class="overlay"></div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="3">
          <p>&nbsp;</p>

          <p class="heading">Resources</p>
          <div class="panel-list">
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/interactive-insights" target="_blank">More interactive insights</a>
            </p>
            <p>
              <a class="list-item" href="https://www.wineaustralia.com/au/selling/by-market/export-market-guide-list" target="_blank">Market export guides</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/gross-margin-ready-reckoner" target="_blank">Gross margin ready reckoner</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/fob-to-retail-calculator" target="_blank">FOB to retail calculator</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/wine-sector-at-a-glance" target="_blank">Australian wine sector at a glance</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/supply-and-demand-the-state-of-play" target="_blank">Wine sector state of play</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/united-states" target="_blank">US market insights</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/united-kingdom" target="_blank">UK market insights</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/china" target="_blank">China market insights</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/regional-snapshots" target="_blank">Regional snapshots</a>
            </p>
            <p>
              <a class="list-item" href="https://wineaustralia.com/market-insights/variety-snapshots" target="_blank">Variety snapshots</a>
            </p>
          </div>
        </b-col>

        <b-col lg="6">
          <p>&nbsp;</p>
          <p class="heading">Portfolio</p>
          <div class="focus-list">
            <div class="article-tile">
              <div class="inner" style="background-image:url(/img/home/new-markets.jpg)">
                <div class="play" style="display:none;">
                  <span class="icon i-video"></span>
                </div>
                <router-link class="link" to="/new-markets">
                  <span class="overlay"></span>
                  <span class="media-tags"></span>
                </router-link>
                <router-link class="title" to="/new-markets">
                  <span class="clamp2" style="overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; display: -webkit-box; -webkit-line-clamp: 2;">New markets</span>
                  <!-- <span class="subtitle">07 Dec 2018 </span> -->
                </router-link>
              </div>
            </div>
            <div class="article-tile">
              <div class="inner" style="background-image:url(/img/home/new-products.jpg)">
                <div class="play" style="display:none;">
                  <span class="icon i-video"></span>
                </div>
                <router-link class="link" to="/new-products">
                  <span class="overlay"></span>
                  <span class="media-tags"></span>
                </router-link>
                <router-link class="title" to="/new-products">
                  <span class="clamp2" style="overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; display: -webkit-box; -webkit-line-clamp: 2;">New products</span>
                  <!-- <span class="subtitle">07 Dec 2018 </span> -->
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="3">
          <p>&nbsp;</p>
          <p class="heading">'How-to' videos</p>
          <div class="panel-list">
            <p>
              <a class="list-item" href="https://youtu.be/a7bawgXGbsw" target="_blank">Explore Markets</a>
            </p>
            <p>
              <a class="list-item" href="https://youtu.be/9oM4yZwmkD8" target="_blank">Explore premium markets</a>
            </p>
            <p>
              <a class="list-item" href="https://youtu.be/CDPCE7-XBO4" target="_blank">Market List</a>
            </p>
            <p>
              <a class="list-item" href="https://youtu.be/5rq7hXe4lPM" target="_blank">Market Comparison</a>
            </p>
            <p>
              <a class="list-item" href="https://youtu.be/HcNOuGR7YZU" target="_blank">New Products</a>
            </p>
            <p>
              <a class="list-item" href="https://youtu.be/iq_pCvND3U0" target="_blank">New Markets</a>
            </p>
          </div>
        </b-col>
      </b-row>


  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    };
  },
  watch: {

  },

  methods: {
    setActiveInterestArea(val) {
      console.log(`set interest area: ${val.value}`);
      if (val != null) {
        this.$store.commit('interestArea/updateSelection', val);
      }
    },

  },
  computed: {

  },
};

</script>


<style scoped>

p.heading {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 26px;
    font-weight: 400;
}

p.body {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.4rem;
    font-weight: 400;
}

.list-group-item {
  border: 0 none !important;
}

.margin-top {
  margin-top: 100%;
}

.square-text {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
  margin: 0;
  padding: 40px 20px 20px 20px;
  line-height: 2;
  font-weight: 400;

  position: absolute;
  top: 15px;
  bottom: 20px;
  left: 15px;
  right: 15px;
  text-align: center;
  padding-top: 20%;
  z-index: 9;
  background: transparent;
  color: #fff;

}

.square-img {
  position: absolute;
  top: 15px;
  bottom: 20px;
  left: 15px;
  right: 15px;
  background-size: cover;
}

.square-img > .overlay {
    width:100%;
    height:100%;
    position:absolute;
    background-color:#000;
    opacity:0.5;
}

.square-img.square-img-1 {
  background: url('/img/home/squares/square-01.jpg') no-repeat;
}

.square-img.square-img-2 {
  background: url('/img/home/squares/square-02.jpg') no-repeat;
}

.square-img.square-img-3 {
  background: url('/img/home/squares/square-03.jpg') no-repeat;
}

.square-img.square-img-4 {
  background: url('/img/home/squares/square-04.jpg') no-repeat;
}

.square-img.square-img-5 {
  background: url('/img/home/squares/square-05.jpg') no-repeat;
}

.square-img.square-img-6 {
  background: url('/img/home/squares/square-06.jpg') no-repeat;
}

.square-img.square-img-7 {
  background: url('/img/home/squares/square-07.jpg') no-repeat;
}

.square-img.square-img-8 {
  background: url('/img/home/squares/square-08.jpg') no-repeat;
}

.square-text:hover {
    background-color:#222;
}

@media (min-width: 992px) {
  .panel {
    padding-left: 0;
  }
}
.panel {
  background-color: #f0eeea;
}

.panel .image {
    background-size: cover;
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 350px;
}

@media (min-width: 1500px) {
  .square-text {
    font-size: 26px;
  }
}

@media (min-width: 992px) and (max-width: 1499px) {
  .square-text {
    font-size: 21px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .square-text {
    font-size: 26px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .square-text {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .square-text {
    font-size: 26px;
  }
}

@media (min-width: 1024px) {
  .homepage-news .focus-list {
      margin: 0 -1rem;
  }
}

@media (min-width: 1024px) {
  .homepage-news .focus-list .article-tile:first-child {
      width: 100%;
      float: left;
      position: relative;
      padding: 0 1rem;
  }
}

@media (min-width: 768px) {
  .homepage-news .focus-list .article-tile, .homepage-news .focus-list .article-tile:first-child {
      width: 100%;
      float: left;
      position: relative;
      padding: 0 1rem;
  }
}

@media (min-width: 600px) {
  .homepage-news .focus-list, .homepage-news .latest-list {
      margin: 0 -1rem;
  }
}

@media (min-width: 600px) {
  .homepage-news .focus-list .article-tile:first-child {
      width: 100%;
      float: left;
      position: relative;
      padding: 0 1rem;
  }
}

@media (min-width: 1025px) {
  .homepage-news .focus-list .article-tile:first-child .inner {
      height: 344px;
  }
}

.article-tile .inner {
    position: relative;
    height: 300px;
    background-position: center;
    background-size: cover;
}

.article-tile .inner .play {
    background-color: #66c5b8;
    text-align: center;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 30px;
    color: #FFF;
    width: 30px;
}

.article-tile .inner .link {
    display: block;
    color: #000;
    text-decoration: none;
}

.homepage-news .focus-list .article-tile .title {
    background-color: rgba(255,255,255,.8);
    padding: 20px;
}

.article-tile .inner .overlay {
    background-color: rgba(255,255,255,.75);
    transition-property: opacity;
    transition-duration: .5s;
    transition-timing-function: ease;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.media-tags {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: block;
    font-size: 20px;
    transition: opacity .5s ease;
}

.article-tile .inner .title {
    position: relative;
    bottom: 0;
    right: 0;
    z-index: 2;
    color: #000;
    background-color: rgba(255,255,255,.8);
    padding: 20px;
}

.article-tile .subtitle {
    font-size: 14px;
    font-size: 1.4rem;
    text-transform: uppercase;
    display: block;
    margin-top: 5px;
    color: #999;
    transition: color .5s ease;
}

.article-tile.title-to-bottom .inner .title, .event-landing-promo .article-tile .inner .title, .filter-search-results .article-tile .inner .title, .focus-list .article-tile .inner .title, .latest-stories-list .article-tile .inner .title, .latest-stories-whats-happening .article-tile .inner .title {
    position: absolute!important;
}

.article-tile .inner .title, .article-tile .inner .title .tag {
    transition-property: color;
    transition-duration: .5s;
    transition-timing-function: ease;
    left: 0;
    display: block;
}

@media (min-width: 1025px) {
  .article-tile .inner:hover .title, footer .copyright a:hover, footer .sitemap nav a:hover, footer .sitemap nav a:hover .icon, footer .sitemap nav a:hover b {
      color: #66c5b8;
  }
}

@media (min-width: 1025px) {
  .article-tile .inner:hover .overlay {
      opacity: 1;
  }
}

.article-tile .inner .overlay {
    background-color: rgba(255,255,255,.75);
    transition-property: opacity;
    transition-duration: .5s;
    transition-timing-function: ease;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.focus-list .article-tile {
    margin-bottom: 2rem;
}

.panel-list {
  background-color: #eceae5;
  position: relative;
  padding: 20px;
}

.panel-list .list-item {
  text-decoration: none;
  color: #66c5b8;
  font-family: Walsheim,Arial,Helvetica,sans-serif;
  padding: 0;
  line-height: 2;
  font-size: 20px;
  font-weight: 400;
}

</style>
