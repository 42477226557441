// year filter module

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'annual', label: 'Full year' },
      { value: 'growth-yoy', label: 'Short-term growth' },
      { value: 'growth-5yr', label: 'Long-term growth' },
    ],
    selection: { value: 'annual', label: 'Full year' },
  },

  mutations: {
    updateSelection(state, year) {
      state.selection = year;
      console.log(`Selected value measure: ${state.selection.value}`);
    },
  },

  getters: {
    selectedItem: state => state.selection,
    selectedValueArray: state => [state.selection.value],
  },

  actions: {

  },


};
