<template>
  <div id="map">
    <b-row>
      <b-col lg="12">
        <p class="heading">Market Rank by Key Indicator</p>
        <p class="body">Use this table to rank markets by a number of indicators based on consumption and Australian wine exports. The table can be sorted by each individual item by clicking on the relevant column heading.<br />
        For example, if you are interested in markets with a high Australian share of imported wine consumption, click on the column heading, Australian Share of Imported Consumption.
        </p>
        <p>&nbsp;</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="2">
        <p class="heading">Year</p>
        <v-select @input="setActiveYear" :options="yearOptions" :value="$store.getters['year/selectedItem']" :searchable="false" placeholder=""></v-select>
      </b-col>
      <!-- <b-col lg="3">
        <p class="heading">Market classification</p>
        <v-select @input="setActiveMarketClassifications" :options="$store.state.global.marketClassificationOptions" :value="marketClassifications" multiple placeholder="All classifications"></v-select>
      </b-col> -->
      <b-col lg="2">
        <p class="heading">Region</p>
        <v-select @input="setActiveRegions" :options="$store.state.region.options" :value="regions" multiple :searchable="false" placeholder="All regions"></v-select>
      </b-col>
      <b-col lg="7">
        <p class="heading">&nbsp;</p>
      </b-col>
      <!-- <b-col lg="1">
        <p class="heading">&nbsp;</p>
        <p class="heading text-right d-none d-lg-block">
        <b-link class="heading" to="/print/MA9" target="_blank" style="color: #777" >
          <font-awesome-icon icon="print" />
        </b-link>
        </p>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col lg="12">
        &nbsp;
      </b-col>
    </b-row>
    <b-row >
      <b-col lg="12" >
        <div>
        <MA9 v-if="pageLoaded"
          @loaded= "reportLoaded()"
        />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MA9 from '@/components/reports/MA9.vue';
// import vSelect from 'vue-select';
import vSelect from '@/components/widgets/CustomSelect/components/CustomSelect.vue';

export default {
  name: 'MarketList',
  components: {
    MA9,
    vSelect,
  },
  data() {
    return {
      pbiEmbedConfig: '',
      // marketClassifications: [],
      regions: [],
      pageLoaded: false,
    };
  },

  watch: {
  },

  methods: {
    resetToDefaultFilterValues() {
      this.setActiveYear({ value: 2022, label: '2022' });
      this.setActiveRegions([]);
    },

    printClicked() {
      console.log('print clicked');
      window.print();
    },

    setActiveYear(val) {
      console.log(`set year: ${val.value}`);
      if (val != null) {
        this.$store.commit('year/updateSelection', val);
      }
    },

    // setActiveMarketClassifications(val) {
    //   console.log(`set market classifications: ${val.length}`);
    //   this.$store.commit('global/setActiveMarketClassifications', val);
    // },

    setActiveRegions(val) {
      console.log(`set regions: ${val.length}`);
      this.$store.commit('region/updateSelections', val);
    },

    reportLoaded() {
      console.log('MA9 reportLoaded())');
    },
  },

  mounted() {
    // reset filters to default for page
    this.resetToDefaultFilterValues();

    this.regions = [...this.$store.getters['region/selectedItems']];

    this.pageLoaded = true;
  },


  computed: {
    yearOptions() {
      switch (this.$store.getters['interestArea/selectedItem'].value) {
        default:
          return this.$store.state.year.options;
      }
    },

    environment() {
      return this.$store.state.global.environment;
    },
  },
};

</script>


<style scoped>
p.body {
    font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.4rem;
    font-weight: 400;
}

p.heading {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.7rem;
    font-weight: 400;
}

.list-group-item {
  border: 0 none !important;
}

#map {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

/* .marked {
  min-height: 1000px;
  height: 1000px;
} */

</style>

<style>
/* .v-select .dropdown-toggle .clear {
  visibility: hidden;
}

.v-select .selected-tag {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  color: #888 !important;
}

.v-select ::-webkit-input-placeholder {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
  font-size: 1.6rem !important;
  font-weight: 400 !important;
} */

</style>
