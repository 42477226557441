import { render, staticRenderFns } from "./NewMarkets.vue?vue&type=template&id=817e7db6&scoped=true&"
import script from "./NewMarkets.vue?vue&type=script&lang=js&"
export * from "./NewMarkets.vue?vue&type=script&lang=js&"
import style0 from "./NewMarkets.vue?vue&type=style&index=0&id=817e7db6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "817e7db6",
  null
  
)

export default component.exports