// interestArea filter module

export default {
  namespaced: true,

  state: {
    options: [
      { index: 0, value: 'MA-33-1', label: 'Which markets drink the most wine?' },
      { index: 1, value: 'MA-33-2', label: 'How does the average wine price per bottle compare across markets?' },
      { index: 2, value: 'MA-33-3', label: 'Which markets consume the most imported wine?' },
      { index: 3, value: 'MA-33-4', label: 'What is the share of on vs off-premise in each market?' },
      { index: 4, value: 'MA-33-5', label: 'How much wine consumed in each market is premium?' },
      { index: 5, value: 'MA-33-6', label: 'What is wine\'s share of the total alcohol market?' },
      { index: 6, value: 'MA-33-7', label: 'Where is Australian wine exported to?' },
      { index: 7, value: 'MA-33-8', label: 'Where are Australian wines exported to by price point?' },
    ],

    optionsMobile: [
      { index: 0, value: 'MA-33-1', label: 'Which markets drink the most wine?' },
      { index: 1, value: 'MA-33-2', label: 'What is the average wine price?' },
      { index: 2, value: 'MA-33-3', label: 'Where is imported wine consumed?' },
      { index: 3, value: 'MA-33-4', label: 'On vs off premise share of consumption' },
      { index: 4, value: 'MA-33-5', label: 'Premium vs commercial share of consumption' },
      { index: 5, value: 'MA-33-6', label: 'Wine\'s share of the alcohol market' },
      { index: 6, value: 'MA-33-7', label: 'Where is Australian wine exported to?' },
      { index: 7, value: 'MA-33-8', label: 'Australian wine exports by FOB price point' },
    ],

    selection: { value: 'MA-33-1', label: 'Which markets drink the most wine?' },
    selectedIndex: 0,
  },


  mutations: {
    // updateSelection(state, interestArea) {
    //   state.selection = interestArea;
    //   console.log(`Selected interest area: ${state.selection.value}`);
    // },
    updateSelectedIndex(state, index) {
      state.selectedIndex = index;
      state.selection = state.options[index];
      console.log(`Selected interest area: ${state.selection.value}`);
    },
    updateSelectedIndexMobile(state, index) {
      state.selectedIndex = index;
      state.selection = state.optionsMobile[index];
      console.log(`Selected mobile interest area: ${state.selection.value}`);
    },
  },

  getters: {
    selectedItem: state => state.selection,
    selectedValueArray: state => [state.selection.value],
  },

  actions: {

  },


};
