// fobPricePerLitre filter module (supports multiple selection)
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    options: [
      { value: '$0.00 - $0.49', label: '$0.00 - $0.49' },
      { value: '$0.50 - $0.99', label: '$0.50 - $0.99' },
      { value: '$1.00 - $1.49', label: '$1.00 - $1.49' },
      { value: '$1.50 - $1.99', label: '$1.50 - $1.99' },
      { value: '$2.00 - $2.49', label: '$2.00 - $2.49' },
      { value: '$2.50 - $4.99', label: '$2.50 - $4.99' },
      { value: '$5.00 - $7.49', label: '$5.00 - $7.49' },
      { value: '$7.50 - $9.99', label: '$7.50 - $9.99' },
      { value: '$10.00 - $14.99', label: '$10.00 - $14.99' },
      { value: '$15.00 - $19.99', label: '$15.00 - $19.99' },
      { value: '$20.00 - $29.99', label: '$20.00 - $29.99' },
      { value: '$30.00 - $49.99', label: '$30.00 - $49.99' },
      { value: '$50.00 - $99.99', label: '$50.00 - $99.99' },
      { value: '$100.00 - $199.99', label: '$100.00 - $199.99' },
      { value: '$200 and Over', label: '$200 and Over' },

    ],
    selections: [],

  },

  mutations: {
    updateSelections(state, items) {
      Vue.set(state, 'selections', [...items]);
      console.log(`Selected fobPricePerLitre count: ${state.selections.length}`);
      for (let i = 0; i < state.selections.length; i += 1) {
        console.log(`Selected fobPricePerLitre: ${state.selections[i].value}`);
      }
    },
  },

  getters: {
    selectedItems: state => state.selections,
    selectedValueArray: state => state.selections.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []),
  },

  actions: {

  },


};
