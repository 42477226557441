// container filter module (supports multiple selection)
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'Packaged', label: 'Packaged' },
      { value: 'Unpackaged', label: 'Unpackaged' },

    ],
    selections: [],

  },

  mutations: {
    updateSelections(state, items) {
      Vue.set(state, 'selections', [...items]);
      console.log(`Selected container count: ${state.selections.length}`);
      for (let i = 0; i < state.selections.length; i += 1) {
        console.log(`Selected container: ${state.selections[i].value}`);
      }
    },
  },

  getters: {
    selectedItems: state => state.selections,
    selectedValueArray: state => state.selections.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []),
  },

  actions: {

  },


};
