<template>
  <router-view></router-view>
</template>

<script>
import env from '@/_env';

export default {
  name: 'app',

  created() {
    // here we initialise the environment variable in the store
    this.$store.commit('global/setEnvironment', env.getEnv());
    console.log(`Environment: ${this.environment.id}`);
    console.log(`API URL: ${this.environment.apiUrl}`);
  },

  mounted() {
  },

  computed: {
    environment() {
      return this.$store.state.global.environment;
    },
  },
};
</script>


<style lang="scss">
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
  @import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
  @import "~@fortawesome/fontawesome-free/scss/solid.scss";
  @import "~@fortawesome/fontawesome-free/scss/brands.scss";

  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';

  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';

  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';

  // Import Main styles for this application
  @import 'assets/scss/style';
  @import 'assets/css/main.css'
</style>
