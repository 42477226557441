// giRegion filter module (supports multiple selection)
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    options: [
      { value: 'Adelaide', label: 'Adelaide' },
      { value: 'Adelaide Blend', label: 'Adelaide Blend' },
      { value: 'Adelaide Hills', label: 'Adelaide Hills' },
      { value: 'Adelaide Hills Blend', label: 'Adelaide Hills Blend' },
      { value: 'Adelaide Plains', label: 'Adelaide Plains' },
      { value: 'Adelaide Plains Blend', label: 'Adelaide Plains Blend' },
      { value: 'Albany', label: 'Albany' },
      { value: 'Albany Blend', label: 'Albany Blend' },
      { value: 'Alpine Valleys', label: 'Alpine Valleys' },
      { value: 'Alpine Valleys Blend', label: 'Alpine Valleys Blend' },
      { value: 'Australia', label: 'Australia' },
      { value: 'Barossa', label: 'Barossa' },
      { value: 'Barossa Blend', label: 'Barossa Blend' },
      { value: 'Barossa Valley', label: 'Barossa Valley' },
      { value: 'Barossa Valley Blend', label: 'Barossa Valley Blend' },
      { value: 'Beechworth', label: 'Beechworth' },
      { value: 'Beechworth Blend', label: 'Beechworth Blend' },
      { value: 'Bendigo', label: 'Bendigo' },
      { value: 'Bendigo Blend', label: 'Bendigo Blend' },
      { value: 'Big Rivers', label: 'Big Rivers' },
      { value: 'Big Rivers Blend', label: 'Big Rivers Blend' },
      { value: 'Blackwood Valley', label: 'Blackwood Valley' },
      { value: 'Blackwood Valley Blend', label: 'Blackwood Valley Blend' },
      { value: 'Broke Fordwich', label: 'Broke Fordwich' },
      { value: 'Broke Fordwich Blend', label: 'Broke Fordwich Blend' },
      { value: 'Canberra District', label: 'Canberra District' },
      { value: 'Canberra District Blend', label: 'Canberra District Blend' },
      { value: 'Central Ranges', label: 'Central Ranges' },
      { value: 'Central Ranges Blend', label: 'Central Ranges Blend' },
      { value: 'Central Victoria', label: 'Central Victoria' },
      { value: 'Central Victoria Blend', label: 'Central Victoria Blend' },
      { value: 'Central Western Australia', label: 'Central Western Australia' },
      { value: 'Clare Valley', label: 'Clare Valley' },
      { value: 'Clare Valley Blend', label: 'Clare Valley Blend' },
      { value: 'Coonawarra', label: 'Coonawarra' },
      { value: 'Coonawarra Blend', label: 'Coonawarra Blend' },
      { value: 'Cowra', label: 'Cowra' },
      { value: 'Cowra Blend', label: 'Cowra Blend' },
      { value: 'Currency Creek', label: 'Currency Creek' },
      { value: 'Currency Creek Blend', label: 'Currency Creek Blend' },
      { value: 'Denmark', label: 'Denmark' },
      { value: 'Denmark Blend', label: 'Denmark Blend' },
      { value: 'Eden Valley', label: 'Eden Valley' },
      { value: 'Eden Valley Blend', label: 'Eden Valley Blend' },
      { value: 'Fleurieu', label: 'Fleurieu' },
      { value: 'Fleurieu Blend', label: 'Fleurieu Blend' },
      { value: 'Frankland River', label: 'Frankland River' },
      { value: 'Frankland River Blend', label: 'Frankland River Blend' },
      { value: 'Geelong', label: 'Geelong' },
      { value: 'Geelong Blend', label: 'Geelong Blend' },
      { value: 'Geographe', label: 'Geographe' },
      { value: 'Geographe Blend', label: 'Geographe Blend' },
      { value: 'Gippsland', label: 'Gippsland' },
      { value: 'Gippsland Blend', label: 'Gippsland Blend' },
      { value: 'Glenrowan', label: 'Glenrowan' },
      { value: 'Glenrowan Blend', label: 'Glenrowan Blend' },
      { value: 'Goulburn Valley', label: 'Goulburn Valley' },
      { value: 'Goulburn Valley Blend', label: 'Goulburn Valley Blend' },
      { value: 'Grampians', label: 'Grampians' },
      { value: 'Grampians Blend', label: 'Grampians Blend' },
      { value: 'Granite Belt', label: 'Granite Belt' },
      { value: 'Granite Belt Blend', label: 'Granite Belt Blend' },
      { value: 'Great Southern', label: 'Great Southern' },
      { value: 'Great Southern Blend', label: 'Great Southern Blend' },
      { value: 'Great Western', label: 'Great Western' },
      { value: 'Great Western Blend', label: 'Great Western Blend' },
      { value: 'Greater Perth', label: 'Greater Perth' },
      { value: 'Gundagai', label: 'Gundagai' },
      { value: 'Gundagai Blend', label: 'Gundagai Blend' },
      { value: 'Hastings River', label: 'Hastings River' },
      { value: 'Hastings River Blend', label: 'Hastings River Blend' },
      { value: 'Heathcote', label: 'Heathcote' },
      { value: 'Heathcote Blend', label: 'Heathcote Blend' },
      { value: 'Henty', label: 'Henty' },
      { value: 'Henty Blend', label: 'Henty Blend' },
      { value: 'High Eden', label: 'High Eden' },
      { value: 'High Eden Blend', label: 'High Eden Blend' },
      { value: 'Hilltops', label: 'Hilltops' },
      { value: 'Hilltops Blend', label: 'Hilltops Blend' },
      { value: 'Hunter', label: 'Hunter' },
      { value: 'Hunter Blend', label: 'Hunter Blend' },
      { value: 'Hunter Valley', label: 'Hunter Valley' },
      { value: 'Hunter Valley Blend', label: 'Hunter Valley Blend' },
      { value: 'Kangaroo Island', label: 'Kangaroo Island' },
      { value: 'Kangaroo Island Blend', label: 'Kangaroo Island Blend' },
      { value: 'King Valley', label: 'King Valley' },
      { value: 'King Valley Blend', label: 'King Valley Blend' },
      { value: 'Langhorne Creek', label: 'Langhorne Creek' },
      { value: 'Langhorne Creek Blend', label: 'Langhorne Creek Blend' },
      { value: 'Lenswood', label: 'Lenswood' },
      { value: 'Lenswood Blend', label: 'Lenswood Blend' },
      { value: 'Limestone Coast', label: 'Limestone Coast' },
      { value: 'Limestone Coast Blend', label: 'Limestone Coast Blend' },
      { value: 'Lower Murray', label: 'Lower Murray' },
      { value: 'Lower Murray Blend', label: 'Lower Murray Blend' },
      { value: 'Macedon Ranges', label: 'Macedon Ranges' },
      { value: 'Macedon Ranges Blend', label: 'Macedon Ranges Blend' },
      { value: 'Manjimup', label: 'Manjimup' },
      { value: 'Manjimup Blend', label: 'Manjimup Blend' },
      { value: 'Margaret River', label: 'Margaret River' },
      { value: 'Margaret River Blend', label: 'Margaret River Blend' },
      { value: 'McLaren Vale', label: 'McLaren Vale' },
      { value: 'McLaren Vale Blend', label: 'McLaren Vale Blend' },
      { value: 'Mornington Peninsula', label: 'Mornington Peninsula' },
      { value: 'Mornington Peninsula Blend', label: 'Mornington Peninsula Blend' },
      { value: 'Mount Barker', label: 'Mount Barker' },
      { value: 'Mount Barker Blend', label: 'Mount Barker Blend' },
      { value: 'Mount Benson', label: 'Mount Benson' },
      { value: 'Mount Benson Blend', label: 'Mount Benson Blend' },
      { value: 'Mount Gambier', label: 'Mount Gambier' },
      { value: 'Mount Gambier Blend', label: 'Mount Gambier Blend' },
      { value: 'Mount Lofty Ranges', label: 'Mount Lofty Ranges' },
      { value: 'Mount Lofty Ranges Blend', label: 'Mount Lofty Ranges Blend' },
      { value: 'Mudgee', label: 'Mudgee' },
      { value: 'Mudgee Blend', label: 'Mudgee Blend' },
      { value: 'Murray Darling', label: 'Murray Darling' },
      { value: 'Murray Darling Blend', label: 'Murray Darling Blend' },
      { value: 'Nagambie Lakes', label: 'Nagambie Lakes' },
      { value: 'Nagambie Lakes Blend', label: 'Nagambie Lakes Blend' },
      { value: 'New England Australia', label: 'New England Australia' },
      { value: 'New England Australia Blend', label: 'New England Australia Blend' },
      { value: 'New South Wales', label: 'New South Wales' },
      { value: 'New South Wales Blend', label: 'New South Wales Blend' },
      { value: 'No Label Claim', label: 'No Label Claim' },
      { value: 'North East Victoria', label: 'North East Victoria' },
      { value: 'North East Victoria Blend', label: 'North East Victoria Blend' },
      { value: 'North West Victoria', label: 'North West Victoria' },
      { value: 'North West Victoria Blend', label: 'North West Victoria Blend' },
      { value: 'Northern Rivers', label: 'Northern Rivers' },
      { value: 'Northern Slopes', label: 'Northern Slopes' },
      { value: 'Northern Slopes Blend', label: 'Northern Slopes Blend' },
      { value: 'Orange', label: 'Orange' },
      { value: 'Orange Blend', label: 'Orange Blend' },
      { value: 'Padthaway', label: 'Padthaway' },
      { value: 'Padthaway Blend', label: 'Padthaway Blend' },
      { value: 'Peel', label: 'Peel' },
      { value: 'Peel Blend', label: 'Peel Blend' },
      { value: 'Pemberton', label: 'Pemberton' },
      { value: 'Pemberton Blend', label: 'Pemberton Blend' },
      { value: 'Perricoota', label: 'Perricoota' },
      { value: 'Perricoota Blend', label: 'Perricoota Blend' },
      { value: 'Perth Hills', label: 'Perth Hills' },
      { value: 'Perth Hills Blend', label: 'Perth Hills Blend' },
      { value: 'Piccadilly Valley', label: 'Piccadilly Valley' },
      { value: 'Piccadilly Valley Blend', label: 'Piccadilly Valley Blend' },
      { value: 'Pokolbin', label: 'Pokolbin' },
      { value: 'Pokolbin Blend', label: 'Pokolbin Blend' },
      { value: 'Porongurup', label: 'Porongurup' },
      { value: 'Porongurup Blend', label: 'Porongurup Blend' },
      { value: 'Port Phillip', label: 'Port Phillip' },
      { value: 'Port Phillip Blend', label: 'Port Phillip Blend' },
      { value: 'Pyrenees', label: 'Pyrenees' },
      { value: 'Pyrenees Blend', label: 'Pyrenees Blend' },
      { value: 'Queensland', label: 'Queensland' },
      { value: 'Queensland Blend', label: 'Queensland Blend' },
      { value: 'Riverina', label: 'Riverina' },
      { value: 'Riverina Blend', label: 'Riverina Blend' },
      { value: 'Riverland', label: 'Riverland' },
      { value: 'Riverland Blend', label: 'Riverland Blend' },
      { value: 'Robe', label: 'Robe' },
      { value: 'Robe Blend', label: 'Robe Blend' },
      { value: 'Rutherglen', label: 'Rutherglen' },
      { value: 'Rutherglen Blend', label: 'Rutherglen Blend' },
      { value: 'Shoalhaven Coast', label: 'Shoalhaven Coast' },
      { value: 'South Australia', label: 'South Australia' },
      { value: 'South Australia Blend', label: 'South Australia Blend' },
      { value: 'South Burnett', label: 'South Burnett' },
      { value: 'South Burnett Blend', label: 'South Burnett Blend' },
      { value: 'South Coast', label: 'South Coast' },
      { value: 'South Coast Blend', label: 'South Coast Blend' },
      { value: 'South Eastern Australia', label: 'South Eastern Australia' },
      { value: 'South Eastern Australia Blend', label: 'South Eastern Australia Blend' },
      { value: 'South West Australia', label: 'South West Australia' },
      { value: 'South West Australia Blend', label: 'South West Australia Blend' },
      { value: 'Southern Fleurieu', label: 'Southern Fleurieu' },
      { value: 'Southern Fleurieu Blend', label: 'Southern Fleurieu Blend' },
      { value: 'Southern Highlands', label: 'Southern Highlands' },
      { value: 'Southern Highlands Blend', label: 'Southern Highlands Blend' },
      { value: 'Southern New South Wales', label: 'Southern New South Wales' },
      { value: 'Southern New South Wales Blend', label: 'Southern New South Wales Blend' },
      { value: 'Strathbogie Ranges', label: 'Strathbogie Ranges' },
      { value: 'Strathbogie Ranges Blend', label: 'Strathbogie Ranges Blend' },
      { value: 'Sunbury', label: 'Sunbury' },
      { value: 'Sunbury Blend', label: 'Sunbury Blend' },
      { value: 'Swan District', label: 'Swan District' },
      { value: 'Swan District Blend', label: 'Swan District Blend' },
      { value: 'Swan Hill', label: 'Swan Hill' },
      { value: 'Swan Hill Blend', label: 'Swan Hill Blend' },
      { value: 'Swan Valley', label: 'Swan Valley' },
      { value: 'Swan Valley Blend', label: 'Swan Valley Blend' },
      { value: 'Tasmania', label: 'Tasmania' },
      { value: 'Tasmania Blend', label: 'Tasmania Blend' },
      { value: 'The Peninsulas', label: 'The Peninsulas' },
      { value: 'The Peninsulas Blend', label: 'The Peninsulas Blend' },
      { value: 'Tumbarumba', label: 'Tumbarumba' },
      { value: 'Tumbarumba Blend', label: 'Tumbarumba Blend' },
      { value: 'Upper Goulburn', label: 'Upper Goulburn' },
      { value: 'Upper Goulburn Blend', label: 'Upper Goulburn Blend' },
      { value: 'Upper Hunter Valley', label: 'Upper Hunter Valley' },
      { value: 'Victoria', label: 'Victoria' },
      { value: 'Victoria Blend', label: 'Victoria Blend' },
      { value: 'Western Australia', label: 'Western Australia' },
      { value: 'Western Australia Blend', label: 'Western Australia Blend' },
      { value: 'Western Plains', label: 'Western Plains' },
      { value: 'Western Plains Blend', label: 'Western Plains Blend' },
      { value: 'Western Victoria', label: 'Western Victoria' },
      { value: 'Western Victoria Blend', label: 'Western Victoria Blend' },
      { value: 'Wrattonbully', label: 'Wrattonbully' },
      { value: 'Wrattonbully Blend', label: 'Wrattonbully Blend' },
      { value: 'Yarra Valley', label: 'Yarra Valley' },
      { value: 'Yarra Valley Blend', label: 'Yarra Valley Blend' },

    ],
    selections: [],

  },

  mutations: {
    updateSelections(state, items) {
      Vue.set(state, 'selections', [...items]);
      console.log(`Selected giRegion count: ${state.selections.length}`);
      for (let i = 0; i < state.selections.length; i += 1) {
        console.log(`Selected giRegion: ${state.selections[i].value}`);
      }
    },
  },

  getters: {
    selectedItems: state => state.selections,
    selectedValueArray: state => state.selections.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []),
  },

  actions: {

  },


};
