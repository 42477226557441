// global state module
// import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    environment: null,
  },

  mutations: {

    setEnvironment(state, environment) {
      state.environment = environment;
    },

  },

  getters: {

  },

  actions: {

  },


};
