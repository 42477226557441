<template>
  <div>
    <button
      :display="display"
      :mobile="mobile"
      :class="classList"
      type="button"
      @click="sidebarToggle" >
      <span class="navbar-toggler-icon" />
    </button>
    <SidebarToggler ref="toggler" class="d-md-down-none" style="visibility: hidden;"
      :defaultOpen="defaultOpen" :display="display" :mobile="mobile" />
  </div>
</template>

<script>
import { SidebarToggler } from '@coreui/vue';

export default {
  name: 'CustomSidebarToggler',
  components: { SidebarToggler },
  props: {
    defaultOpen: {
      type: Boolean,
      default: true,
    },
    display: {
      type: String,
      default: 'lg',
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      return [
        'navbar-toggler',
      ];
    },
  },
  mounted() {
    this.toggle(this.defaultOpen);
  },
  methods: {
    toggle(force) {
      this.$refs.toggler.toggle(force);
    },

    sidebarToggle(e) {
      e.preventDefault();
      e.stopPropagation();
      this.toggle();
      this.$root.$emit('viewChange');
    },

  },
};
</script>
