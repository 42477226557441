<template>
  <div class="desktop-view" align="center" >
    <div :id="containerId" class="embedContainer" />
  </div>
</template>

<script>
import axios from 'axios';
import * as pbi from 'powerbi-client';

export default {
  name: 'PbiReport_MA_11Labels',
  components: {
  },

  props: [
    'countryIndex',
    'width',
    'height',
  ],

  data() {
    return {
      pbiEmbedConfig: '',
      report: null,
      resizeDebounceTimeout: null,
    };
  },

  methods: {
    applyFilters() {
      console.log(`ignoring filter MA11Labels - index ${this.countryIndex}`);
    },


    reportLoaded() {
      // we raise an event for the parent page
      this.$emit('loaded');
      this.applyFilters();

      console.log(`ENV >>>>> ${this.environment.id}`);
    },

    // debug method - lists visuals for report
    sliceyDicey() {
      // slicer tests (DEBUG)
      this.report.getPages()
        .then((pages) => {
          let myPage = null;
          pages.forEach((page) => {
            if (page.name === this.reportPage) {
              myPage = page;
              console.log(`${page.name} - ${page.displayName} <- this page`);
            }
          });

          myPage.getVisuals()
            .then((visuals) => {
              visuals.forEach((visual) => {
                console.log(visual);
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },


    processWindowResize() {
      if (this.resizeDebounceTimeout != null) {
        clearTimeout(this.resizeDebounceTimeout);
      }

      this.resizeDebounceTimeout = setTimeout(() => {
        if (!document.getElementById(this.containerId)) {
          console.log('resized page is null');
        } else {
          const width = document.getElementById(this.containerId).offsetWidth;
          const height = document.getElementById(this.containerId).offsetHeight;

          console.log(`resized! ${width} x ${height}`);
        }


      // const newSettings = {
      //   // Settings accounting for custom layout with new size
      // };
      // visual.updateSettings(newSettings);
      }, 1000);
    },

  },

  computed: {
    // report IDs
    workspaceId() {
      return this.environment.workspaceId;
    },
    reportId() {
      return this.environment.reports.ma11Labels.reportId;
    },
    reportPage() {
      return this.environment.reports.ma11Labels.pageId;
    },

    containerId() {
      return `embeddedContent${this.reportId}${this.countryIndex}`;
    },

    environment() {
      console.log(this.$store.state.global.environment.id);
      return this.$store.state.global.environment;
    },
  },

  watch: {
  },

  async mounted() {
    await axios
      .post(`https://${this.environment.apiUrl}/api/token`, {
        workspaceId: this.workspaceId,
        reportId: this.reportId,
      })
      .then((response) => {
        this.pbiEmbedConfig = response.data;
      })
      .catch(error => (console.log(`Error querying API for embed config: ${error.data}`)));

    // const accessToken = this.pbiEmbedConfig.;

    const { models } = pbi;

    // Get embedContainer width and height
    const pageWidth = document.getElementById(this.containerId).offsetWidth;
    const pageHeight = document.getElementById(this.containerId).offsetHeight;

    console.log(`dims: ${pageWidth} x ${pageHeight}`);

    const config = {
      // minimum params
      type: 'report',
      embedUrl: this.pbiEmbedConfig.embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: this.pbiEmbedConfig.embedToken.token,
      id: this.reportId,
      pageName: this.reportPage,

      permissions: models.Permissions.Read, // open read-only
      viewMode: models.ViewMode.View, // open in view mode
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: models.BackgroundType.Transparent,
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToPage,
        },
      },
      filters: [], // set this to programatically apply filters
    };

    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

    const embedContainer = document.getElementById(this.containerId);
    this.report = powerbi.embed(embedContainer, config);


    this.report.on('loaded', () => {
      this.reportLoaded();
    });

    this.report.on('rendered', () => {
      console.log('handled rendered');

      // debug
      this.sliceyDicey();
    });

    this.report.on('dataSelected', () => {
      console.log('handled dataSelected');
    });

    this.report.on('filtersApplied', () => {
      console.log('handled filters applied');
    });

    // add a resize event handler with debounce
    window.addEventListener('resize', this.processWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.processWindowResize);
  },


};

</script>


<style scoped>

.embedContainer {
    width: 100%;
    height: calc(100vh*1.6);
    background-color: white;
    padding: 0px;
    clear: both;
}
.desktop-view iframe, .mobile-view iframe {
    border: none;
}

</style>
