<template>
  <div id="map">
    <b-row>
      <b-col lg="4">
        <p class="heading">Market</p>
        <v-select @input="setActiveCountry" :options="$store.state.country.options" :value="$store.getters['country/selectedItem']" :searchable="true" placeholder=""></v-select>
      </b-col>
      <!-- <b-col lg="7">
        <p class="heading">&nbsp;</p>
      </b-col> -->
      <!-- <b-col lg="1">
        <p class="heading">&nbsp;</p>
        <p class="heading text-right d-none d-lg-block">
        <b-link class="heading" to="/print/MA10" target="_blank" style="color: #777" >
          <font-awesome-icon icon="print" />
        </b-link>
        </p>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col lg="12">
        &nbsp;
      </b-col>
    </b-row>
    <b-row >
      <b-col lg="12" >
        <div>
        <MA10
          @loaded= "reportLoaded()"
        />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MA10 from '@/components/reports/MA10.vue';
import vSelect from '@/components/widgets/CustomSelect/components/CustomSelect.vue';

export default {
  name: 'MarketSummary',
  components: {
    MA10,
    vSelect,
  },
  data() {
    return {
      pbiEmbedConfig: '',
    };
  },
  watch: {
  },

  methods: {
    printClicked() {
      console.log('print clicked');
      window.print();
    },

    setActiveCountry(val) {
      console.log(`set year: ${val.value}`);
      if (val != null) {
        this.$store.commit('country/updateSelection', val);
      }
    },

    reportLoaded() {
      console.log('MA10 reportLoaded()');
    },
  },

  mounted() {
  },


  computed: {
    environment() {
      return this.$store.state.global.environment;
    },
  },
};

</script>


<style scoped>

p.heading {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2;
    font-size: 1.7rem;
    font-weight: 400;
}

.list-group-item {
  border: 0 none !important;
}

#map {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

/* .marked {
  min-height: 1000px;
  height: 1000px;
} */

</style>

<style>
/* .v-select .dropdown-toggle .clear {
  visibility: hidden;
}

.v-select .selected-tag {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  color: #888 !important;
}

.v-select ::-webkit-input-placeholder {
  font-family: Walsheim,Arial,Helvetica,sans-serif;
  font-size: 1.6rem !important;
  font-weight: 400 !important;
} */

</style>
